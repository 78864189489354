import React, { useEffect, useState } from "react";
import InputTag from "../../../../Components/InputTag.jsx";
import { NAAC } from "../../../../utils/apiConstants.js";
import axios from "axios";
import { toast } from "react-toastify";

import { v4 as uuidv4 } from "uuid";
export default function PotentialForExcellenceRecognition({
  setLoading,
  setUpdatedData,
}) {
  // State to manage form data
  const [formData, setFormData] = useState({
    id: "",
    cpe_by_ugc: "",
    date_of_recognition_ugc: "",
    upe_by_other_agency: "",
    name_of_other_agency: "",
    date_of_recognition_other_agency: "",
    created_by: "",
    updated_by: "",
    university_profile_id: "",
  });
  let emp_id = sessionStorage.getItem("employee_id");
  // State to conditionally render additional input fields
  const [showCPEFields, setShowCPEFields] = useState(false);
  const [showAgencyFields, setShowAgencyFields] = useState(false);

  // Handler to update form data
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const getFormData = async () => {
    setLoading(1);

    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      //  Make this url dynamic after adding the university or college id to the university-profile table
      url: `${NAAC}/potential-for-excellence/1`,
    };
    await axios(config)
      .then((res) => {
        let data = res.data[0];
        if (data) {
          setFormData(data);
        }
        setLoading(0);

        // console.log("data fetched is", res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      })
      .finally(() => {
        setLoading(0);
      });
  };

  const getFilteredFormData = () => {
    const filteredData = { ...formData };

    // Remove `date_of_recognition_ugc` if "No" is selected for `cpe_by_ugc`
    if (filteredData.cpe_by_ugc !== "Yes") {
      filteredData.date_of_recognition_ugc = "";
    }

    // Remove fields related to other agency if "No" is selected for `upe_by_other_agency`
    if (filteredData.upe_by_other_agency !== "Yes") {
      filteredData.date_of_recognition_other_agency = "";
      filteredData.name_of_other_agency = "";
    }

    return filteredData;
  };
  const handleSaveAsDraft = async (e) => {
    e.preventDefault();
    const filteredData = getFilteredFormData();
    setLoading(1);
    const config = {
      method: "post",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: `${NAAC}/potential-for-excellence`,
      data: {
        ...filteredData,
        status: "DRAFT",
        university_profile_id: 1,
        created_by: emp_id,
        updated_by: emp_id,
        application_id: 1,
      },
    };
    await axios(config)
      .then((res) => {
        toast.success(" Successfully saved as draft");
        getFormData();
        setUpdatedData(uuidv4());
        setLoading(0);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      })
      .finally(() => {
        setLoading(0);
      });
  };

  const handleSubmitForReview = async (e) => {
    setLoading(1);
    const filteredData = getFilteredFormData();
    e.preventDefault();
    const config = {
      method: "patch",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: `${NAAC}/potential-for-excellence`,
      data: {
        ...filteredData,
        status: "REVIEW",
        university_profile_id: 1,
        created_by: emp_id,
        updated_by: emp_id,
        application_id: 1,
      },
    };
    await axios(config)
      .then((res) => {
        toast.success(" Successfully submitted for review");
        getFormData();
        setLoading(0);
        setUpdatedData(uuidv4());
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      })
      .finally(() => {
        setLoading(0);
      });
  };

  useEffect(() => {
    getFormData();
  }, []);
  console.log("formData", formData);
  return (
    <>
      <span className="fw-semibold h5">
        11. Potential for Excellence Recognition (CPE) by UGC
      </span>
      <span
        className="text-white text-center bg-secondary px-4 py-1 shadow-sm rounded-pill"
        style={{ width: "fit-content" }}
      >
        Not Started
      </span>

      <form className="mt-4">
        <div className="row">
          {/* College Recognition Section */}
          <div className="mb-4 col-md-6">
            <label
              htmlFor="potential-excellence"
              className="form-label fw-semibold"
            >
              Is the College Recognized by UGC as a College with Potential For
              Excellence (CPE)?
            </label>
            <div className="d-flex flex-column my-3">
              <select
                id="potential-excellence"
                name="cpe_by_ugc"
                className="form-control"
                value={formData.cpe_by_ugc}
                onChange={handleInputChange}
              >
                <option disabled value="">
                  Select Option
                </option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
              {formData.cpe_by_ugc === "Yes" && (
                <InputTag
                  type="date"
                  name="date_of_recognition_ugc"
                  label="Date Of Recognition"
                  value={formData.date_of_recognition_ugc}
                  onChange={handleInputChange}
                />
              )}
            </div>
          </div>

          {/* Other Government Agency Section */}
          <div className="mb-4 col-md-6">
            <label
              htmlFor="government-agency"
              className="form-label fw-semibold"
            >
              Is the College Recognized for its performance by any Other
              Government Agency?
            </label>
            <div className="d-flex flex-column my-3">
              <select
                id="government-agency"
                name="upe_by_other_agency"
                className="form-control"
                value={formData.upe_by_other_agency}
                onChange={handleInputChange}
              >
                <option disabled value="">
                  Select Option
                </option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
              {formData.upe_by_other_agency === "Yes" && (
                <>
                  <InputTag
                    type="text"
                    name="name_of_other_agency"
                    label="Name Of Agency"
                    value={formData.name_of_other_agency}
                    onChange={handleInputChange}
                  />
                  <InputTag
                    type="date"
                    name="date_of_recognition_other_agency"
                    label="Date of Recognition"
                    value={formData.date_of_recognition_other_agency}
                    onChange={handleInputChange}
                  />
                </>
              )}
            </div>
          </div>
        </div>

        {/* Buttons for saving and submitting */}
        <div className="d-flex justify-content-end gap-3 my-4">
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleSaveAsDraft}
          >
            Save as Draft
          </button>
          <button
            type="button"
            className="btn btn-warning text-dark"
            onClick={handleSubmitForReview}
          >
            Submit for Review
          </button>
        </div>
      </form>
    </>
  );
}
