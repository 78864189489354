import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Divider from "../../../../Components/Divider.jsx";

export default function AppropriateIntegrationOfIndianKnowledgeSystem() {
  return (
    <>
      <div>
        <div className="d-flex flex-column mb-3">
          <p className="h5">
            4. Appropriate integration of Indian Knowledge System (teaching in
            Indian Language, culture, using online courses)
          </p>
          <span className="text-danger">
            *Note - Maximum word allowed is 500 words
          </span>
        </div>
        <span
          style={{ width: "fit-content" }}
          className="text-white text-nowrap text-center bg-secondary px-3 py-1 shadow-sm rounded-pill"
        >
          Not Started
        </span>
      </div>

      <div>
        <p>
          a) Delineate the strategy and details regarding the integration of the
          Indian Knowledge System (teaching in Indian Language, culture, etc.)
          into the curriculum using both offline and online courses.
        </p>
        <CKEditor
          editor={ClassicEditor}
          onInit={(editor) => {
            console.log("Editor is ready to use!", editor);
          }}
        />
      </div>

      <div>
        <p>
          b) What are the institution's plans to train its faculties to provide
          classroom delivery in a bilingual mode (English and vernacular)?
          Provide the details.
        </p>
        <CKEditor
          editor={ClassicEditor}
          data=""
          onInit={(editor) => {
            console.log("Editor is ready to use!", editor);
          }}
        />
      </div>

      <div>
        <p>
          c) Provide the details of the degree courses taught in Indian
          languages and bilingually in the institution.
        </p>
        <CKEditor
          editor={ClassicEditor}
          data=""
          onInit={(editor) => {
            console.log("Editor is ready to use!", editor);
          }}
        />
      </div>

      <div>
        <p>
          d) Describe the efforts of the institution to preserve and promote the
          following: <br />
          i) Indian languages (Sanskrit, Pali, Prakrit, and classical, tribal,
          and endangered languages, etc.) <br />
          ii) Indian ancient traditional knowledge <br />
          iii) Indian Arts <br />
          iv) Indian Culture and traditions.
        </p>
        <CKEditor
          editor={ClassicEditor}
          data=""
          onInit={(editor) => {
            console.log("Editor is ready to use!", editor);
          }}
        />
      </div>

      <div>
        <p>
          e) Describe any good practice/s of the institution pertaining to the
          appropriate integration of the Indian Knowledge System (teaching in
          Indian Language, culture, using online courses) in view of NEP 2020.
        </p>
        <CKEditor
          editor={ClassicEditor}
          data=""
          onInit={(editor) => {
            console.log("Editor is ready to use!", editor);
          }}
        />
      </div>

      <div className="text-right">
        <button className="btn btn-primary mr-2  ">Save as Draft</button>
        <button className="btn btn-warning ">
          <span>Submit for Review</span>
        </button>
      </div>
    </>
  );
}
