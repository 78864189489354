import React, { useEffect, useState } from "react";
import { NAAC } from "../../utils/apiConstants";
import axios from "axios";
import { Select } from "antd";
import { toast } from "react-toastify";

const ModalApplicationApproval = ({
  type,
  setLoading,
  employeeOpt,
  collegeId,
  reloadData,
  data,
}) => {
  let emp_id = sessionStorage.getItem("employee_id");
  const [formData, setFormData] = useState({
    title: "",
    employee_id: "",
    id: "",
    created_by: "",
    updated_by: "",
    status: "",
  });
  const clearData = {
    title: "",
    employee_id: "",
    id: "",
    created_by: "",
    updated_by: "",
    status: "",
  };
  useEffect(() => {
    if (type === "edit") {
      if (data) {
        setFormData(data);
      }
    }

    if (type === "add") {
      setFormData(clearData);
    }
  }, [data, type]);
  const handleDelete = async () => {
    setLoading(1);
    const config = {
      method: "put",
      url: `${NAAC}/approval-flow/${data?.id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
      data: {
        status: "INACTIVE",
      },
    };
    await axios(config)
      .then((res) => {
        toast.success("success");
        reloadData();
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });
    setLoading(0);
  };
  const handleSubmit = async () => {
    setLoading(1);
    if (!formData.employee_id || !formData.title) {
      setLoading(0);
      return toast.error("Please fill all the fields");
    }
    const config = {
      method: "post",
      url: `${NAAC}/approval-flow`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
      data: {
        ...formData,
        status: "ACTIVE",
        created_by: type === "edit" ? formData.created_by : emp_id,
        updated_by: emp_id,
      },
    };
    await axios(config)
      .then((res) => {
        console.log(res);
        toast.success("Success");
        reloadData();
        setFormData(clearData);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.message);
      });
    setLoading(0);
  };

  return (
    <div
      className="modal fade"
      id="ModalNAACApplicationApproval"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered mw-100 w-75"
        role="document"
      >
        <div className="modal-content ">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              NAAC Application Approval Flow
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="">Title</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Title of the Approver"
                    value={formData.title}
                    onChange={(e) =>
                      setFormData({ ...formData, title: e.target.value })
                    }
                  />
                </div>
                <div className="form-group">
                  <label className="form-label" htmlFor="">
                    Employee
                  </label>

                  <Select
                    style={{ width: "100%" }}
                    showSearch
                    placeholder="Select an Employee"
                    options={employeeOpt}
                    filterOption={(input, option) =>
                      option?.label.toLowerCase().includes(input.toLowerCase())
                    }
                    value={formData.employee_id}
                    onChange={(value) =>
                      setFormData({ ...formData, employee_id: value })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="row d-flex justify-content-between px-2">
              {type == "edit" ? (
                <button
                  className="btn btn-danger btn-rounded btn-outline"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={handleDelete}
                >
                  Delete
                </button>
              ) : (
                <button
                  className="btn btn-info btn-rounded btn-outline"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
              )}
              <button
                className="btn btn-success btn-rounded btn-outline"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  handleSubmit();
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalApplicationApproval;
