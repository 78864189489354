import { Menu } from "antd";
import {
  BarChartOutlined,
  UnorderedListOutlined,
  DatabaseOutlined,
  DownloadOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import React, { useState } from "react";
import { useNavigate, Outlet } from "react-router-dom";

const NAAC = ({ setLoading }) => {
  const navigate = useNavigate();
  const items = [
    {
      key: "dashboard",
      label: "Dashboard",
      icon: <BarChartOutlined />,
      children: [
        {
          key: "/registarar/naac/activity-tracking",
          label: "Activity Tracking",
          icon: <ArrowRightOutlined />,
        },
        {
          key: "/registarar/naac/performance-tracking",
          label: "Performance Tracking",
          icon: <ArrowRightOutlined />,
        },
        {
          key: "/registarar/naac/status-reports",
          label: "Status Reports",
          icon: <ArrowRightOutlined />,
        },
      ],
    },
    {
      key: "data-collection",
      label: "Data Collection",
      icon: <UnorderedListOutlined />,
      children: [
        {
          key: "naac-data",
          label: "NAAC Data",
          icon: <DatabaseOutlined />,
          children: [
            {
              key: "/registarar/naac/executive-summary",
              label: "Executive Summary",
              icon: <ArrowRightOutlined />,
            },
            {
              key: "/registarar/naac/profile",
              label: "Profile",
              icon: <ArrowRightOutlined />,
            },
            {
              key: "/registarar/naac/extended-profile",
              label: "Extended Profile",
              icon: <ArrowRightOutlined />,
            },
            {
              key: "/registarar/naac/nep-parameters",
              label: "NEP Parameters",
              icon: <ArrowRightOutlined />,
            },
            {
              key: "/registarar/naac/qif-qualitative",
              label: "QIF Qualitative",
              icon: <ArrowRightOutlined />,
            },
            {
              key: "/registarar/naac/qif-quantitative",
              label: "QIF Quantitative",
              icon: <ArrowRightOutlined />,
            },
          ],
        },
        {
          key: "/registarar/naac/my-downloads",
          label: "My Downloads",
          icon: <DownloadOutlined />,
        },
      ],
    },
  ];

  const handleOnclick = (e) => {
    navigate(e.key);
  };

  return (
    <div className="d-flex w-100">
      {/* Sidebar Menu */}
      <Menu
        style={{
          width: 256,
        }}
        onClick={handleOnclick}
        defaultSelectedKeys={["/registarar/naac/activity-tracking"]}
        defaultOpenKeys={["data-collection", "dashboard"]}
        mode="inline"
        items={items}
      />

      {/* Content Section */}
      <div className="p-4 flex-grow-1 overflow-auto">
        <div className="min-w-100">
          <Outlet setLoading />
        </div>
      </div>
    </div>
  );
};

export default NAAC;
