import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default function Multidisciplinary() {
  return (
    <>
      <div>
        <div className="d-flex flex-column mb-3">
          <p className="h5">1. Multidisciplinary / Interdisciplinary</p>
          <span className="text-danger">
            *Note - Maximum word allowed is 500 words
          </span>
        </div>
        <span
          style={{ width: "fit-content" }}
          className="text-white text-nowrap text-center bg-secondary px-3 py-1 shadow-sm rounded-pill"
        >
          Not Started
        </span>
      </div>

      <div className="mb-3">
        <p className="fw-bold">
          a) Delineate the vision/plan of institution to transform itself into a
          holistic multidisciplinary institution.
        </p>
        <CKEditor
          editor={ClassicEditor}
          onInit={(editor) => {
            console.log("Editor is ready to use!", editor);
          }}
        />
      </div>

      <div className="mb-3">
        <p className="fw-bold">
          b) Delineate the Institutional approach towards the integration of
          humanities and science with STEM and provide the detail of programs
          with combinations.
        </p>
        <CKEditor
          editor={ClassicEditor}
          data=""
          onInit={(editor) => {
            console.log("Editor is ready to use!", editor);
          }}
        />
      </div>

      <div className="mb-3">
        <p className="fw-bold">
          c) Does the institution offer flexible and innovative curricula that
          include credit-based courses and projects in areas such as community
          engagement and service, environmental education, and value-based
          education?
        </p>
        <CKEditor
          editor={ClassicEditor}
          data=""
          onInit={(editor) => {
            console.log("Editor is ready to use!", editor);
          }}
        />
      </div>

      <div className="mb-3">
        <p className="fw-bold">
          d) What is the institutional plan for offering a multidisciplinary
          flexible curriculum that enables multiple entries and exits at the end
          of 1st, 2nd, and 3rd years of undergraduate education while
          maintaining rigor?
        </p>
        <CKEditor
          editor={ClassicEditor}
          data=""
          onInit={(editor) => {
            console.log("Editor is ready to use!", editor);
          }}
        />
      </div>

      <div className="mb-3">
        <p className="fw-bold">
          e) What are the institutional plans to engage in more
          multidisciplinary research endeavors to address pressing societal
          challenges?
        </p>
        <CKEditor
          editor={ClassicEditor}
          data=""
          onInit={(editor) => {
            console.log("Editor is ready to use!", editor);
          }}
        />
      </div>

      <div className="mb-3">
        <p className="fw-bold">
          f) Describe any good practice/s of the institution to promote a
          Multidisciplinary / Interdisciplinary approach in view of NEP 2020.
        </p>
        <CKEditor
          editor={ClassicEditor}
          data=""
          onInit={(editor) => {
            console.log("Editor is ready to use!", editor);
          }}
        />
      </div>

      <div className="text-right">
        <button className="btn btn-primary mr-2  ">Save as Draft</button>
        <button className="btn btn-warning ">
          <span>Submit for Review</span>
        </button>
      </div>
    </>
  );
}
