import InputTag from "../../../../Components/InputTag.jsx";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { NAAC } from "../../../../utils/apiConstants.js";
import { Select } from "antd";
import { international } from "../../../../Data/Countries/international.js";
import SelectTag from "../../../../Components/SelectTag.jsx";
import { v4 as uuidv4 } from "uuid";
export default function BasicInformation({ setUpdatedData, setLoading }) {
  const [formData, setFormData] = useState({
    id: "",
    name: "",
    address: "",
    city: "",
    state: "",
    country: "",
    pin_code: "",
    website: "",
    nature_of_university: "",
    type_of_institute: "",
    established_date_of_university: "",
    college_affiliated_to_university: "",
    two_f_of_ugc: "",
    twelve_b_of_ugc: "",
    created_by: "",
    source_of_funding: "",
    nature_of_university_manual: "",
    status: "",
  });
  const [countries, setCountries] = useState([]);
  const [city, setCity] = useState([]);
  const handleOnchange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  let emp_id = sessionStorage.getItem("employee_id");
  const setCountryAndState = async () => {
    let arr = [];
    await international?.map((item, key) => {
      arr.push({ label: item?.country, value: item?.country });
    });
    setCountries(arr);
  };

  const getFormData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      //  Make this url dynamic after adding the university or college id to the university-profile table
      url: `${NAAC}/university-profile/1`,
    };

    await axios(config)
      .then((res) => {
        let data = res.data[0];
        if (data) {
          setFormData({
            ...data,
            two_f_of_ugc: data["2f_of_ugc"],
            twelve_b_of_ugc: data["12b_of_ugc"],
          });
        }
        // console.log("data fetched is", res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      })
      .finally(() => {
        setLoading(0);
      });
  };
  useEffect(() => {
    setCountryAndState();
    getFormData();
  }, []);
  useEffect(() => {
    //

    const fetchToken = async () => {
      let response;
      let data;

      try {
        if (formData.country && formData.state) {
          // console.log("Fetching cities for:", formData.country, formData.state);

          response = await fetch(
            "https://countriesnow.space/api/v0.1/countries/state/cities",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                country: formData.country,
                state: formData.state,
              }),
            }
          );

          if (!response.ok) {
            const errorBody = await response.text();
            console.error(`Error: ${response.status}`, errorBody);
            throw new Error(`Error: ${response.status}`);
          }

          // Parsing the JSON response
          data = await response.json();
          setCity(data.data);
          console.log("Fetched cities:", data); // Log the cities
        }
      } catch (error) {
        console.error("Error fetching cities:", error);
      }
    };

    fetchToken();
  }, [formData.country, formData.state]);
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const handleSubmit = async (e) => {
    e.preventDefault();
    // List all required fields
    const requiredFields = [
      "name",
      "address",
      "city",
      "state",
      "country",
      "pin_code",
      "website",
      "nature_of_university",
      "type_of_institute",
      "college_affiliated_to_university",
      "established_date_of_university",
      "nature_of_university",
      "two_f_of_ugc",
      "twelve_b_of_ugc",
      "source_of_funding",
    ];

    // Check if all required fields are filled
    for (const field of requiredFields) {
      if (!formData[field]) {
        toast.error(`Please fill in the ${field.split("_").join(" ")} field`);
        return;
      }
    }
    setLoading(1);
    const config = {
      method: "post",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: `${NAAC}/university-profile`,
      data: {
        ...formData,
        created_by: emp_id,
        updated_by: emp_id,
        status: "DRAFT",
      },
    };
    await axios(config)
      .then((res) => {
        toast.success(" Successfully saved as draft");
        getFormData();
        setUpdatedData(uuidv4());
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      })
      .finally(() => {
        setLoading(0);
      });
  };
  const handleSubmitForReview = async (e) => {
    setLoading(1);
    e.preventDefault();
    // List all required fields
    const requiredFields = [
      "name",
      "address",
      "city",
      "state",
      "country",
      "pin_code",
      "website",
      "nature_of_university",
      "type_of_institute",
      "college_affiliated_to_university",
      "established_date_of_university",
      "nature_of_university",
      "two_f_of_ugc",
      "twelve_b_of_ugc",
      "source_of_funding",
    ];

    // Check if all required fields are filled
    for (const field of requiredFields) {
      if (!formData[field]) {
        toast.error(`Please fill in the ${field.split("_").join(" ")} field`);
        return;
      }
    }

    const config = {
      method: "patch",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: `${NAAC}/university-profile`,
      data: {
        ...formData,
        status: "REVIEW",
        created_by: emp_id,
        updated_by: emp_id,
      },
    };
    await axios(config)
      .then((res) => {
        toast.success(" Successfully submitted for review");
        getFormData();
        setUpdatedData(uuidv4());
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      })
      .finally(() => {
        setLoading(0);
      });
  };

  return (
    <>
      <div className="container mt-5">
        <form>
          <div className="d-flex flex-column gap-5">
            <div>
              <div className="mb-3 d-flex flex-column">
                <span className="fw-semibold text-dark h5">
                  A. Basic Information
                </span>

                <span
                  className={`text-white d-inline-block text-center px-4 py-1 shadow-sm small rounded-pill ${
                    formData.status === "DRAFT"
                      ? "bg-primary"
                      : formData.status === "REVIEW"
                      ? "bg-warning"
                      : formData.status === "REJECTED"
                      ? "bg-danger"
                      : formData.status === "APPROVED"
                      ? "bg-success"
                      : "bg-secondary"
                  }`}
                  style={{ width: "fit-content" }}
                >
                  {formData.status === "DRAFT"
                    ? "In Draft"
                    : formData.status === "REVIEW"
                    ? "Submitted for review"
                    : formData.status === "REJECTED"
                    ? "Rejected"
                    : formData.status === "APPROVED"
                    ? "Approved"
                    : "Not Started"}
                </span>
              </div>

              <div className="mt-3 row g-4">
                <div className="col-md-6">
                  <InputTag
                    type="text"
                    name="name"
                    label="Name"
                    onChange={handleOnchange}
                    value={formData.name}
                    required={true}
                  />
                </div>
                <div className="col-md-6">
                  <InputTag
                    type="text"
                    name="website"
                    label="Website"
                    onChange={handleOnchange}
                    value={formData.website}
                    required={true}
                  />
                </div>
              </div>
              <div className="row g-4">
                <div className="col-md-3 form-group">
                  <label className="form-label" htmlFor="country">
                    Country
                  </label>
                  <Select
                    showSearch
                    id="country"
                    placeholder="Select country"
                    optionFilterProp="children"
                    onChange={(value) => {
                      setFormData({ ...formData, country: value });
                    }}
                    filterOption={filterOption}
                    value={formData.country}
                    options={countries}
                    className="d-block w-100"
                  />
                </div>
                <div className="col-md-3">
                  <label className="form-label" htmlFor="state">
                    State
                  </label>
                  <Select
                    showSearch
                    id="state"
                    placeholder="Select state"
                    optionFilterProp="children"
                    onChange={(value) => {
                      setFormData({ ...formData, state: value });
                    }}
                    filterOption={filterOption}
                    value={formData.state}
                    options={
                      international
                        ?.find((s) => s.country === formData?.country)
                        ?.states?.map((i) => ({ value: i, label: i })) || []
                    }
                    className="d-block w-100"
                  />
                </div>
                <div className="col-md-3">
                  <label className="form-label" htmlFor="city">
                    City
                  </label>
                  {city.length > 0 ? (
                    <Select
                      showSearch
                      onChange={(value) => {
                        setFormData({ ...formData, city: value });
                      }}
                      value={formData.city}
                      className="d-block w-100"
                    >
                      {city.map((item) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))}
                      <option value={"other"}>Other</option>
                    </Select>
                  ) : (
                    <InputTag
                      type="text"
                      name="city"
                      value={formData.city}
                      onChange={handleOnchange}
                    />
                  )}
                  {formData.city === "other" && (
                    <InputTag
                      type="text"
                      name="city"
                      value={formData.city}
                      placeholder="Please enter the city"
                      onChange={(value) => {
                        setFormData({ ...formData, city: value });
                      }}
                    />
                  )}
                </div>
                <div className="col-md-3">
                  <InputTag
                    type="number"
                    name="pin_code"
                    label="Pin Code"
                    onChange={handleOnchange}
                    value={formData.pin_code}
                  />
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="address" className="form-label">
                  Address
                </label>
                <textarea
                  rows={5}
                  name="address"
                  id="address"
                  onChange={handleOnchange}
                  value={formData.address}
                  placeholder="Enter the address of the institution"
                  className="form-control"
                ></textarea>
              </div>
            </div>
            <div class="bg-danger border-2 border-top border-secondary " />
            <div>
              <span className="fw-semibold text-dark h5">
                B. Nature & Type of University
              </span>

              <div className="mt-3 row ">
                <div className="col-md-6 ">
                  <div className="form-group">
                    <label
                      htmlFor="nature_of_university"
                      className="form-label"
                    >
                      Please select the status of Institution
                    </label>
                    <select
                      id="nature_of_university"
                      name="nature_of_university"
                      value={formData.nature_of_university}
                      className="form-control"
                      onChange={(e) => {
                        setFormData((prev) => ({
                          ...prev,
                          nature_of_university: e.target.value,
                        }));
                      }}
                    >
                      <option disabled value="default">
                        Select Option
                      </option>
                      <option value="Affiliated College">
                        Affiliated College
                      </option>
                      <option value="Constituent College">
                        Constituent College
                      </option>
                      <option value="other">Other</option>
                    </select>
                  </div>

                  {formData.nature_of_university === "other" && (
                    <InputTag
                      type="text"
                      name="nature_of_university_manual"
                      value={formData.nature_of_university_manual}
                      label="Other (Please Specify)"
                      onChange={handleOnchange}
                    />
                  )}
                </div>
                <div className="col-md-6">
                  <InputTag
                    type="text"
                    name="type_of_institute"
                    label="Type of institute"
                    value={formData.type_of_institute}
                    onChange={handleOnchange}
                  />
                </div>
              </div>
            </div>
            <div class="bg-danger border-2 border-top border-secondary " />
            <div>
              <span className="fw-semibold text-dark h5">
                C. Date Of Establishment And Affiliation Details
              </span>

              <div className="mt-3 row g-4">
                <div className="col-md-4">
                  <InputTag
                    type="date"
                    name="established_date_of_university"
                    label="Date Of Establishment of College"
                    value={formData.established_date_of_university}
                    onChange={handleOnchange}
                  />
                </div>
                <div className="col-md-8">
                  <InputTag
                    type="text"
                    name="college_affiliated_to_university"
                    label="University to which the college is affiliated or which governs the college (If it is a constituent college)"
                    onChange={handleOnchange}
                    value={formData.college_affiliated_to_university}
                  />
                </div>
              </div>
            </div>
            <div class="bg-danger border-2 border-top border-secondary " />
            <div>
              <span className="fw-semibold text-dark h5">
                D. Recognition Details
              </span>

              <div className="mt-3 row g-4">
                <div className="col-md-6">
                  <InputTag
                    type="date"
                    name="two_f_of_ugc"
                    label="2F of UGC"
                    onChange={handleOnchange}
                    value={formData.two_f_of_ugc}
                  />
                </div>
                <div className="col-md-6">
                  <InputTag
                    type="date"
                    name="twelve_b_of_ugc"
                    label="12B of UGC"
                    onChange={handleOnchange}
                    value={formData.twelve_b_of_ugc}
                  />
                </div>
              </div>
            </div>
            <div class="bg-danger border-2 border-top border-secondary " />
            <div className="d-flex flex-column">
              <span className="fw-semibold text-dark h5">
                E. Source of Funding
              </span>

              <div className="row">
                <div className="mb-3 col-md-6">
                  <SelectTag
                    name="source_of_funding"
                    label="Source Of Funding"
                    data={[
                      { id: 0, value: "Government" },
                      { id: 1, value: "Grant-In-Aid" },
                      { id: 2, value: "Self Financing" },
                      { id: 3, value: "Other" },
                    ]}
                    onChange={handleOnchange}
                    value={formData.source_of_funding}
                  />
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-end gap-3 mb-3">
              <button
                type="submit"
                onClick={handleSubmit}
                className="btn btn-primary"
              >
                Save as Draft
              </button>
              <button
                type="button"
                onClick={handleSubmitForReview}
                className="btn btn-warning text-dark"
              >
                Submit for Review
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
