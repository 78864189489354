import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Divider from "../../../../Components/Divider.jsx";

export default function FocusOnOutcomeBasedEducation() {
  return (
    <>
      <div>
        <div className="d-flex flex-column mb-3">
          <p className="h5">5. Focus on Outcome based education (OBE)</p>
          <span className="text-danger">
            *Note - Maximum word allowed is 500 words
          </span>
        </div>
        <span
          style={{ width: "fit-content" }}
          className="text-white text-nowrap text-center bg-secondary px-3 py-1 shadow-sm rounded-pill"
        >
          Not Started
        </span>
      </div>

      <div>
        <p>
          a) Describe the institutional initiatives to transform its curriculum
          towards Outcome based Education (OBE).
        </p>
        <CKEditor
          editor={ClassicEditor}
          onInit={(editor) => {
            console.log("Editor is ready to use!", editor);
          }}
        />
      </div>

      <div>
        <p>
          b) Explain the efforts made by the institution to capture the Outcome
          based education in teaching and learning practices.
        </p>
        <CKEditor
          editor={ClassicEditor}
          data=""
          onInit={(editor) => {
            console.log("Editor is ready to use!", editor);
          }}
        />
      </div>

      <div>
        <p>
          c) Describe any good practices of the institution pertaining to the
          Outcome based education (OBE) in view of NEP 2020.
        </p>
        <CKEditor
          editor={ClassicEditor}
          data=""
          onInit={(editor) => {
            console.log("Editor is ready to use!", editor);
          }}
        />
      </div>

      <div className="text-right">
        <button className="btn btn-primary mr-2  ">Save as Draft</button>
        <button className="btn btn-warning ">
          <span>Submit for Review</span>
        </button>
      </div>
    </>
  );
}
