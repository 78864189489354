import React, { useEffect, useState } from "react";
import ModalApplicationApproval from "../../modals/NAAC/ModalApplicationApproval";
import { EMPLOYEE_SQL, NAAC } from "../../utils/apiConstants";
import axios from "axios";
import { toast } from "react-toastify";
import Nodata from "../../Components/NoData/Nodata";

const NAACApprovalFlow = ({ setLoading, collegeId }) => {
  const [role, setRole] = useState(
    sessionStorage.getItem("role") ? sessionStorage.getItem("role") : null
  );
  const [data, setData] = useState([]);
  const [employeeOpt, setEmployeeOpt] = useState([]);
  const [edit, setEdit] = useState("");
  const [type, setType] = useState("");
  const getData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${NAAC}/approval-flow?college_id=${collegeId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
    };

    await axios(config)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });
    setLoading(0);
  };
  const getEmployee = async () => {
    const config = {
      method: "get",
      url: `${EMPLOYEE_SQL}?college_id=${collegeId}&status=ACTIVE`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
    };

    await axios(config)
      .then((res) => {
        console.log("employees - ", res.data.data);
        let temp = [];
        let employee = res.data.data;
        employee?.map((i, key) => {
          let obj = {};
          obj["value"] = i?.id;
          obj["label"] =
            i?.first_name + " " + i?.last_name + " (" + i?.role + ")";
          temp.push(obj);
        });
        setEmployeeOpt(temp);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getEmployee();
    getData();
  }, []);

  return (
    <div className="main-content">
      <ModalApplicationApproval
        employeeOpt={employeeOpt}
        setLoading={setLoading}
        collegeId={collegeId}
        reloadData={getData}
        data={edit}
        type={type}
      />
      <div className="page-content">
        <div className="container-fluid">
          <h4 className="h4 mb-4"> NAAC Approval Flow</h4>
          <div className="container">
            <div className="card">
              <div className="card-body bg-nex">
                <div className="row d-flex justify-content-between  p-3">
                  {role !== "ADMIN" ? (
                    <button
                      className="btn btn-rounded btn-success btn-outline px-4"
                      data-toggle="modal"
                      data-target="#ModalNAACApplicationApproval"
                      onClick={() => {
                        setEdit();
                        setType("add");
                      }}
                    >
                      Add +
                    </button>
                  ) : null}
                </div>

                <div>
                  {data && data?.length !== 0 ? (
                    data.map((item, i) =>
                      role !== "ADMIN" ? (
                        <div
                          className="row my-3 mx-2 p-3 border rounded role-div flex-nowrap shadow"
                          data-toggle="modal"
                          data-target="#ModalNAACApplicationApproval"
                          onClick={() => {
                            setEdit(item);
                            setType("edit");
                          }}
                        >
                          <div className="col-2" key={i}>
                            <div>{item.title}</div>
                          </div>
                          <div className="col-9" key={i}>
                            <div className="role-title">{item.name}</div>
                            <div className="role-code">
                              {
                                employeeOpt?.find(
                                  (s) => s.value == item?.employee_id
                                )?.label
                              }
                            </div>
                          </div>
                          <div className="col-1 d-flex align-items-center justify-content-end">
                            {">"}
                          </div>
                        </div>
                      ) : (
                        <div className="row my-3 mx-2 p-3 border rounded role-div flex-nowrap shadow">
                          <div className="col-2" key={i}>
                            <div className="role-title">{item.title}</div>
                          </div>
                          <div className="col-9" key={i}>
                            <div className="role-title">{item.name}</div>
                            <div className="role-code">
                              {
                                employeeOpt?.find(
                                  (s) => s.value == item?.employee_id
                                )?.label
                              }
                            </div>
                          </div>
                          <div className="col-1 d-flex align-items-center justify-content-end">
                            {">"}
                          </div>
                        </div>
                      )
                    )
                  ) : (
                    <Nodata />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NAACApprovalFlow;
