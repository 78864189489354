import React from "react";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import InputTag from "../../../../Components/InputTag.jsx";

export default function DateOfEstablishmentOfInternalQualityAssurance() {
  return (
    <>
      <span className="font-medium">
        23. Date of establishment of Internal Quality Assurance Cell (IQAC) IQAC
      </span>
      <span className="text-white whitespace-nowrap w-28 text-center bg-gray-400 px-4 py-1 shadow-sm text-xs rounded-2xl">
        Not Started
      </span>

      <form className="flex flex-column gap-5">
        <InputTag
          type="date"
          name="date-of-establishment"
          label="Date of establishment of Internal Quality Assurance Cell (IQAC) IQAC"
        />

        <div className="flex justify-start my-3  mb-3">
          <button className="btn btn-primary">Save as Draft</button>
          <button className="btn btn-warning">
            <span>Submit for Review</span>
          </button>
        </div>
      </form>
    </>
  );
}
