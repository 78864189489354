import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Divider from "../../../../Components/Divider.jsx";
import { ArrowRightIcon } from "@heroicons/react/24/outline";

export default function AcademicBankOfCredits() {
  return (
    <>
      <div>
        <div className="d-flex flex-column mb-3">
          <p className="h5">2. Academic bank of credits (ABC)</p>
          <span className="text-danger">
            *Note - Maximum word allowed is 500 words
          </span>
        </div>
        <span
          style={{ width: "fit-content" }}
          className="text-white text-nowrap text-center bg-secondary px-3 py-1 shadow-sm rounded-pill"
        >
          Not Started
        </span>
      </div>
      <div>
        <p>
          a) Describe the initiatives taken by the institution to fulfil the
          requirement of Academic bank of credits as proposed in NEP 2020.
        </p>

        <CKEditor
          editor={ClassicEditor}
          onInit={(editor) => {
            console.log("Editor is ready to use!", editor);
          }}
        />
      </div>

      <div>
        <p>
          b) Whether the institution has registered under the ABC to permit its
          learners to avail the benefit of multiple entries and exit during the
          chosen programme? Provide details.
        </p>

        <CKEditor
          editor={ClassicEditor}
          data=""
          onInit={(editor) => {
            console.log("Editor is ready to use!", editor);
          }}
        />
      </div>
      <div>
        <p>
          c) Describe the efforts of the institution for seamless collaboration,
          internationalization of education, joint degrees between Indian and
          foreign institutions, and to enable credit transfer.
        </p>

        <CKEditor
          editor={ClassicEditor}
          data=""
          onInit={(editor) => {
            console.log("Editor is ready to use!", editor);
          }}
        />
      </div>

      <div>
        <p>
          d) How faculties are encouraged to design their own curricular and
          pedagogical approaches within the approved framework, including
          textbook, reading material selections, assignments, and assessments
          etc.
        </p>

        <CKEditor
          editor={ClassicEditor}
          data=""
          onInit={(editor) => {
            console.log("Editor is ready to use!", editor);
          }}
        />
      </div>

      <div>
        <p>
          e) Describe any good practice/s of the institution pertaining to the
          implementation of Academic bank of credits (ABC) in the institution in
          view of NEP 2020.
        </p>

        <CKEditor
          editor={ClassicEditor}
          data=""
          onInit={(editor) => {
            console.log("Editor is ready to use!", editor);
          }}
        />
      </div>

      <div className="text-right">
        <button className="btn btn-primary mr-2  ">Save as Draft</button>
        <button className="btn btn-warning ">
          <span>Submit for Review</span>
        </button>
      </div>
    </>
  );
}
