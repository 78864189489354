import React from "react";
import { DocumentArrowDownIcon } from "@heroicons/react/24/outline";
import InputTag from "../../../../Components/InputTag";
import { Button, Upload } from "antd";

export default function TotalNumberOfClassroomsAndSeminarHalls() {
  return (
    <>
      {/* Header Section */}
      <div className="d-flex justify-content-between align-items-end w-100 mb-4">
        <div className="d-flex flex-column gap-2">
          <span className="fw-semibold fs-5">
            Total number of Classrooms and Seminar halls
          </span>
          <span
            style={{ width: "fit-content" }}
            className="bg-secondary text-white text-center px-3 py-1 rounded-pill shadow-sm"
          >
            Not Started
          </span>
        </div>
      </div>

      {/* Input Section */}
      <div className="mb-4">
        <InputTag
          type="number"
          name="total"
          label="Total number of Classrooms and Seminar halls"
        />
      </div>

      {/* Upload Section */}
      <div className="mb-4">
        <div className="py-3 d-flex flex-column gap-3">
          <span className="fw-medium">Upload Data Template</span>
          <div className="d-flex align-items-center gap-3">
            <DocumentArrowDownIcon
              className="me-2"
              style={{ height: "24px", width: "24px" }}
            />
            <a
              href="/templates/Extended Profile 4.1 Template.xlsx"
              className="text-primary text-decoration-underline"
              download
            >
              4.1 Template
            </a>
          </div>
          <Upload accept=".pdf,.docs,.xlsx,.zip,.jpg,.png">
            <Button type="primary">Upload</Button>
          </Upload>
        </div>
      </div>

      {/* Action Buttons */}
      <div className="d-flex justify-content-end gap-3 mb-4">
        <button className="btn btn-primary">Save as Draft</button>
        <button className="btn btn-warning text-dark">
          <span>Submit for Review</span>
        </button>
      </div>
    </>
  );
}
