import React, { useState } from "react";
import { Select, Table } from "antd";

const columns = [
  {
    title: "Metric",
    dataIndex: "metric",
    key: "metric",
  },
  {
    title: "Data From",
    dataIndex: "dataFrom",
    key: "dataFrom",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (_, { status }) => (
      <>
        {status.map((tag, index) => (
          <div
            key={index}
            className={`p-2 badge text-center w-100 rounded text-white ${
              tag === "Not Started"
                ? "bg-secondary"
                : tag === "In Draft"
                ? "bg-primary"
                : tag === "Submitted"
                ? "bg-warning"
                : tag === "Rejected"
                ? "bg-danger"
                : tag === "Approved"
                ? "bg-success"
                : "bg-info"
            }`}
          >
            {tag}
          </div>
        ))}
      </>
    ),
  },
  {
    title: "Author / Reviewer",
    dataIndex: "author",
    key: "author",
  },
  {
    title: "Approver",
    dataIndex: "approver",
    key: "approver",
  },
  {
    title: "Last Updated",
    dataIndex: "lastUpdated",
    key: "lastUpdated",
  },
  {
    title: "Schedule Date",
    dataIndex: "scheduleDate",
    key: "scheduleDate",
  },
  {
    title: "Days Left",
    dataIndex: "daysLeft",
    key: "daysLeft",
  },
];

const data = [
  {
    key: "1.1.1",
    metric: "1.1.1",
    dataFrom: "",
    status: [],
    author: "",
    approver: "",
    lastUpdated: "",
    scheduleDate: "",
    daysLeft: "",
    children: [
      {
        key: "1.1.1.1",
        metric: "",
        dataFrom: "Kle Technological University",
        status: ["Not Started"],
        author: "Naveen Kulkarni",
        approver: "Naveen Kulkarni",
        lastUpdated: "Mon 14 Oct 2024",
        scheduleDate: "Mon 14 Oct 2024",
        daysLeft: 1,
        children: [
          {
            key: "1.1.1.1.1",
            metric: "",
            dataFrom: "MBA",
            status: ["Approved"],
            author: "Author MBA",
            approver: "Approver MBA",
            lastUpdated: "Mon 14 Oct 2024",
            scheduleDate: "Mon 14 Oct 2024",
            daysLeft: 1,
          },
          {
            key: "1.1.1.1.2",
            metric: "",
            dataFrom: "Engineering",
            status: ["Rejected"],
            author: "Author Engineering",
            approver: "Approver Engineering",
            lastUpdated: "Wed 09 Oct 2024",
            scheduleDate: "Mon 14 Oct 2024",
            daysLeft: 1,
          },
          {
            key: "1.1.1.1.3",
            metric: "",
            dataFrom: "Law",
            status: ["Not Started"],
            author: "Author Law",
            approver: "Approver Law",
            lastUpdated: "Mon 14 Oct 2024",
            scheduleDate: "Mon 14 Oct 2024",
            daysLeft: 1,
          },
        ],
      },
    ],
  },
  {
    key: "1.1.2",
    metric: "1.1.2",
    dataFrom: "",
    status: [],
    author: "",
    approver: "",
    lastUpdated: "",
    scheduleDate: "",
    daysLeft: "",
    children: [
      {
        key: "1.1.2.1",
        metric: "",
        dataFrom: "Kle Technological University",
        status: ["Evaluated"],
        author: "Naveen Kulkarni",
        approver: "Naveen Kulkarni",
        lastUpdated: "Mon 14 Oct 2024",
        scheduleDate: "Mon 14 Oct 2024",
        daysLeft: 1,
        children: [
          {
            key: "1.1.2.1.1",
            metric: "",
            dataFrom: "MBA",
            status: ["In Draft"],
            author: "Author MBA",
            approver: "Approver MBA",
            lastUpdated: "Mon 14 Oct 2024",
            scheduleDate: "Mon 14 Oct 2024",
            daysLeft: 1,
          },
        ],
      },
    ],
  },
];

export default function ActivityTracking() {
  const [expandedRowKeys, setExpandedRowKeys] = useState([
    "1.1.1",
    "1.1.1.1",
    "1.1.2",
  ]);

  return (
    <div className="mb-4">
      <div className="shadow-lg p-4 border my-2 mb-16 bg-white">
        <div className="d-flex flex-column gap-4 mb-4">
          <div className="d-flex justify-content-between flex-column mb-3 align-items-start flex-md-row align-items-md-center">
            <h3>QIF Activity Status</h3>
          </div>
          <Select
            defaultValue="KLETechnologicalUniversity"
            className="form-select"
            options={[
              {
                value: "KLETechnologicalUniversity",
                label: "KLE Technological University",
              },
              {
                value: "engineering",
                label: "Engineering",
              },
              {
                value: "hotelMangement",
                label: "Hotel Mangement",
              },
              {
                value: "law",
                label: "Law",
              },
              {
                value: "MBA",
                label: "MBA",
              },
            ]}
          />
          {/* <div className="d-flex flex-column gap-2">
            <h2>Overall Status</h2>

            <div className="row">
              <div className="col-md-2 bg-secondary text-white p-3 rounded">
                <h5 className="text-white">Not Started</h5>
                <p>99.14%</p>
              </div>
              <div className="col-md-2 bg-primary text-white p-3 rounded">
                <h5 className="text-white">In Draft</h5>
                <p>0.86%</p>
              </div>
              <div className="col-md-2 bg-warning text-white p-3 rounded">
                <h5 className="text-white">Submitted</h5>
                <p>0.0%</p>
              </div>
              <div className="col-md-2 bg-danger text-white p-3 rounded">
                <h5 className="text-white">Rejected</h5>
                <p>0.0%</p>
              </div>
              <div className="col-md-2 bg-success text-white p-3 rounded">
                <h5 className="text-white">Approved</h5>
                <p>0.0%</p>
              </div>
              <div className="col-md-2 bg-info text-white p-3 rounded">
                <h5 className="text-white">Evaluated</h5>
                <p>0.0%</p>
              </div>
            </div>
          </div> */}
          <div className="d-flex flex-column gap-2">
            <div className="row">
              <div className="col-md-2 col-sm-4 col-6">
                <div className="card bg-secondary text-white p-3 rounded">
                  <p className="h5 text-white">Not Started</p>
                  <p className=" h2 text-white">99.14%</p>
                </div>
              </div>
              <div className="col-md-2 col-sm-4 col-6">
                <div className="card bg-primary text-white p-3 rounded">
                  <p className="h5 text-white">In Draft</p>
                  <p className=" h2 text-white">0.86%</p>
                </div>
              </div>
              <div className="col-md-2 col-sm-4 col-6">
                <div className="card bg-warning text-white p-3 rounded">
                  <p className="h5 text-white">Submitted</p>
                  <p className=" h2 text-white">0.86%</p>
                </div>
              </div>
              <div className="col-md-2 col-sm-4 col-6">
                <div className="card bg-danger text-white p-3 rounded">
                  <p className="h5 text-white">Rejected</p>
                  <p className=" h2 text-white">0.0%</p>
                </div>
              </div>
              <div className="col-md-2 col-sm-4 col-6">
                <div className="card bg-success text-white p-3 rounded">
                  <p className="h5 text-white">Approved</p>
                  <p className=" h2 text-white">0.0%</p>
                </div>
              </div>
              <div className="col-md-2 col-sm-4 col-6">
                <div className="card bg-info text-white p-3 rounded">
                  <p className="h5 text-white">Evaluated</p>
                  <p className=" h2 text-white">0.0%</p>
                </div>
              </div>
            </div>
          </div>

          <hr />
          {/* Table */}
          <div className="table-responsive">
            <Table
              className="table"
              columns={columns}
              dataSource={data}
              expandable={{
                expandedRowKeys, // Set expanded row keys
                onExpandedRowsChange: (expandedRows) => {
                  setExpandedRowKeys(expandedRows);
                },
              }}
              pagination={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
