import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default function DistanceEducation() {
  return (
    <>
      <div>
        <div className="d-flex flex-column mb-3">
          <p className="h5">6. Distance education/online education</p>
          <span className="text-danger">
            *Note - Maximum word allowed is 500 words
          </span>
        </div>
        <span
          style={{ width: "fit-content" }}
          className="text-white text-nowrap text-center bg-secondary px-3 py-1 shadow-sm rounded-pill"
        >
          Not Started
        </span>
      </div>

      <div>
        <p>
          a) Delineate the possibilities of offering vocational courses through
          ODL mode in the institution.
        </p>
        <CKEditor
          editor={ClassicEditor}
          onInit={(editor) => {
            console.log("Editor is ready to use!", editor);
          }}
        />
      </div>

      <div>
        <p>
          b) Describe the development and use of technological tools for
          teaching-learning activities. Provide details about the institutional
          efforts towards blended learning.
        </p>
        <CKEditor
          editor={ClassicEditor}
          data=""
          onInit={(editor) => {
            console.log("Editor is ready to use!", editor);
          }}
        />
      </div>

      <div>
        <p>
          c) Describe any good practices of the institution pertaining to
          distance education/online education in view of NEP 2020.
        </p>
        <CKEditor
          editor={ClassicEditor}
          data=""
          onInit={(editor) => {
            console.log("Editor is ready to use!", editor);
          }}
        />
      </div>

      <div className="text-right">
        <button className="btn btn-primary mr-2  ">Save as Draft</button>
        <button className="btn btn-warning ">
          <span>Submit for Review</span>
        </button>
      </div>
    </>
  );
}
