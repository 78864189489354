import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Divider from "../../../../Components/Divider.jsx";
import { ArrowRightIcon } from "@heroicons/react/24/outline";

export default function SkillDevelopment() {
  return (
    <>
      <div>
        <div className="d-flex flex-column mb-3">
          <p className="h5">3. Skill Development</p>
          <span className="text-danger">
            *Note - Maximum word allowed is 500 words
          </span>
        </div>
        <span
          style={{ width: "fit-content" }}
          className="text-white text-nowrap text-center bg-secondary px-3 py-1 shadow-sm rounded-pill"
        >
          Not Started
        </span>
      </div>

      <div>
        <p>
          a) Describe the efforts made by the institution to strengthen the
          vocational education and soft skills of students in alignment with
          National Skills Qualifications Framework.
        </p>
        <CKEditor
          editor={ClassicEditor}
          onInit={(editor) => {
            console.log("Editor is ready to use!", editor);
          }}
        />
      </div>

      <div>
        <p>
          b) Provide the details of the programmes offered to promote vocational
          education and its integration into mainstream education.
        </p>
        <CKEditor
          editor={ClassicEditor}
          data=""
          onInit={(editor) => {
            console.log("Editor is ready to use!", editor);
          }}
        />
      </div>

      <div>
        <p>
          c) How the institution is providing Value-based education to inculcate
          positivity amongst the learner that includes the development of
          humanistic, ethical, Constitutional, and universal human values of
          truth (satya), righteous conduct (dharma), peace (shanti), love
          (prem), nonviolence (ahimsa), scientific temper, citizenship values,
          and life-skills.
        </p>
        <CKEditor
          editor={ClassicEditor}
          data=""
          onInit={(editor) => {
            console.log("Editor is ready to use!", editor);
          }}
        />
      </div>

      <div>
        <p>
          d) Enlist the institution's efforts to: <br />
          i) Design a credit structure to ensure that all students take at least
          one vocational course before graduating. <br />
          ii) Engaging the services of Industry veterans and Master Crafts
          persons to provide vocational skills and address faculty gaps. <br />
          iii) Offer vocational education in ODL/blended/on-campus modular
          modes. <br />
          iv) NSDC association to manage learner enrolment, skill mapping, and
          certification. <br />
          v) Offer skilling courses through online and/or distance mode.
        </p>
        <CKEditor
          editor={ClassicEditor}
          data=""
          onInit={(editor) => {
            console.log("Editor is ready to use!", editor);
          }}
        />
      </div>

      <div>
        <p>
          e) Describe any good practice/s of the institution pertaining to Skill
          Development in view of NEP 2020.
        </p>
        <CKEditor
          editor={ClassicEditor}
          data=""
          onInit={(editor) => {
            console.log("Editor is ready to use!", editor);
          }}
        />
      </div>

      <div className="text-right">
        <button className="btn btn-primary mr-2  ">Save as Draft</button>
        <button className="btn btn-warning ">
          <span>Submit for Review</span>
        </button>
      </div>
    </>
  );
}
