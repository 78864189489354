import { Routes, Route, Navigate } from "react-router-dom";
import ExtendedProfile from "../Pages/NAAC/extended-profile/ExtendedProfile.jsx";

// Sub Pages of Extended Profile Page
import NumberOfCoursesOfferedByTheInstitution from "../Pages/NAAC/extended-profile/sub-pages/NumberOfCoursesOfferedByTheInstitution";
import NumberOfStudentsYearWise from "../Pages/NAAC/extended-profile/sub-pages/NumberOfStudentsYearWise";
import NumberOfSeatsEarmarkedForReservedCategory from "../Pages/NAAC/extended-profile/sub-pages/NumberOfSeatsEarmarkedForReservedCategory";
import NumberOfOutgoingStudents from "../Pages/NAAC/extended-profile/sub-pages/NumberOfOutgoingStudents";
import NumberOfFullTimeTeachers from "../Pages/NAAC/extended-profile/sub-pages/NumberOfFullTimeTeachers";
import NumberOfSanctionedPosts from "../Pages/NAAC/extended-profile/sub-pages/NumberOfSanctionedPosts";
import TotalNumberOfClassroomsAndSeminarHalls from "../Pages/NAAC/extended-profile/sub-pages/TotalNumberOfClassroomsAndSeminarHalls";
import TotalExpenditureExcludingSalary from "../Pages/NAAC/extended-profile/sub-pages/TotalExpenditureExcludingSalary";
import NumberOfComputers from "../Pages/NAAC/extended-profile/sub-pages/NumberOfComputers";
import axios from "axios";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { NAAC } from "../utils/apiConstants.js";
import Loader from "../Components/Loader/Loader.jsx";

export default function ExtendedProfileRoutes() {
  const [updatedData, setUpdatedData] = useState("");
  const [loading, setLoading] = useState(0);
  const [data, setData] = useState(null);
  const getFormData = async () => {
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: `${NAAC}/get-all-status`,
    };
    await axios(config)
      .then((res) => {
        setData(res?.data);
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });
  };
  useEffect(() => {
    // getFormData();
  }, [updatedData]);
  return (
    <>
      <Loader loading={loading} />
      <Routes>
        <Route
          path="/"
          element={
            <Navigate to="/registarar/naac/extended-profile/number-of-courses-offered-by-the-institution" />
          }
        />
        <Route
          path="number-of-courses-offered-by-the-institution"
          element={
            <ExtendedProfile data={data}>
              <NumberOfCoursesOfferedByTheInstitution
                setUpdatedData={setUpdatedData}
                setLoading={setLoading}
              />
            </ExtendedProfile>
          }
        />
        <Route
          path="number-of-students-year-wise"
          element={
            <ExtendedProfile data={data}>
              <NumberOfStudentsYearWise
                setUpdatedData={setUpdatedData}
                setLoading={setLoading}
              />
            </ExtendedProfile>
          }
        />
        <Route
          path="number-of-seats-earmarked-for-reserved-category"
          element={
            <ExtendedProfile data={data}>
              <NumberOfSeatsEarmarkedForReservedCategory
                setUpdatedData={setUpdatedData}
                setLoading={setLoading}
              />
            </ExtendedProfile>
          }
        />
        <Route
          path="number-of-outgoing-students"
          element={
            <ExtendedProfile data={data}>
              <NumberOfOutgoingStudents
                setUpdatedData={setUpdatedData}
                setLoading={setLoading}
              />
            </ExtendedProfile>
          }
        />
        <Route
          path="number-of-full-time-teachers"
          element={
            <ExtendedProfile data={data}>
              <NumberOfFullTimeTeachers
                setUpdatedData={setUpdatedData}
                setLoading={setLoading}
              />
            </ExtendedProfile>
          }
        />
        <Route
          path="number-of-sanctioned-posts"
          element={
            <ExtendedProfile data={data}>
              <NumberOfSanctionedPosts
                setUpdatedData={setUpdatedData}
                setLoading={setLoading}
              />
            </ExtendedProfile>
          }
        />
        <Route
          path="total-number-of-classrooms-and-seminar-halls"
          element={
            <ExtendedProfile data={data}>
              <TotalNumberOfClassroomsAndSeminarHalls
                setUpdatedData={setUpdatedData}
                setLoading={setLoading}
              />
            </ExtendedProfile>
          }
        />
        <Route
          path="total-expenditure-excluding-salary"
          element={
            <ExtendedProfile data={data}>
              <TotalExpenditureExcludingSalary
                setUpdatedData={setUpdatedData}
                setLoading={setLoading}
              />
            </ExtendedProfile>
          }
        />
        <Route
          path="number-of-computers"
          element={
            <ExtendedProfile data={data}>
              <NumberOfComputers
                setUpdatedData={setUpdatedData}
                setLoading={setLoading}
              />
            </ExtendedProfile>
          }
        />
      </Routes>
    </>
  );
}
