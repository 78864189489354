import React, { useEffect, useState } from "react";
import { Button, Popconfirm, Table, Modal, Form, Input, Upload } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  FileExcelOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { ASSET_EMPLOYEE_DOCUMENT } from "../../../../utils/AssetsReferenceTypes";
import { getFileUrl } from "../../../../Helpers/Helpers";
import { NAAC } from "../../../../utils/apiConstants";

export default function NumberOfStudentsYearWise({
  setUpdatedData,
  setLoading,
}) {
  const [data, setData] = useState([]);
  const [editingRecord, setEditingRecord] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [badge, setBadge] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [uploadedFile, setUploadedFile] = useState(null);
  const [form] = Form.useForm();

  const emp_id = sessionStorage.getItem("employee_id");

  const handleEdit = (record) => {
    setEditingRecord(record);
    form.setFieldsValue(record);
    setIsModalVisible(true);
  };

  const handleAdd = () => {
    form.resetFields();
    setEditingRecord(null);
    setIsModalVisible(true);
  };

  const handleDelete = (identifier) => {
    const updatedData = data.filter((item) =>
      item.id === identifier
        ? (item.status = "INACTIVE")
        : item.tempId !== identifier
    );
    setData(updatedData);
  };

  const handleSave = () => {
    form.validateFields().then((values) => {
      if (editingRecord) {
        setData(
          data.map((item) =>
            (item.id && item.id === editingRecord.id) ||
            (item.tempId && item.tempId === editingRecord.tempId)
              ? { ...item, ...values, document: fileUrl, updated_by: emp_id }
              : item
          )
        );
      } else {
        setData([
          ...data,
          {
            ...values,
            tempId: uuidv4(),
            created_by: emp_id,
            updated_by: emp_id,
            document: fileUrl,
            status: "DRAFT",
          },
        ]);
      }
      setIsModalVisible(false);
    });
  };

  const handleSaveAsDraft = async (e) => {
    e.preventDefault();

    if (data.length === 0) {
      return toast.error("Please add at least one contact detail");
    }

    // Separate data for backend operations
    const toDelete = data.filter(
      (item) => item.status === "INACTIVE" && item.id
    );
    const toUpdate = data.filter(
      (item) => item.status !== "INACTIVE" && item.id
    );
    const toCreate = data.filter((item) => !item.id && item.tempId); // Items with a tempId but no backend id

    const config = {
      method: "post",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: `${NAAC}/ugc-recognition`,
      data: { toDelete, toUpdate, toCreate },
    };

    await axios(config)
      .then((res) => {
        toast.success("Successfully saved as draft");
        console.log("FormData", res);
        getFormData(); // Refetch data to update view
        setUpdatedData(uuidv4());
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      });
  };

  const handleSaveAndSubmitForReview = async () => {
    setLoading(1);
    if (data.length === 0) {
      return toast.error(
        "Please add at least one contact detail before submitting for review"
      );
    }

    const config = {
      method: "patch",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: `${NAAC}/ugc-recognition`, // Replace '2' with dynamic id if needed
      data: { data }, // Send all data in the current state
    };

    await axios(config)
      .then((res) => {
        toast.success(res.data.message);
        getFormData(); // Refresh data to update the view
        setUpdatedData(uuidv4());
        setLoading(1);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      })
      .finally(() => {
        setLoading(0);
      });
  };
  const columns = [
    {
      title: "Year",
      dataIndex: "year",
      key: "year",
    },
    {
      title: "Number of Courses",
      dataIndex: "number",
      key: "number",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <>
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => handleEdit(record)}
          >
            Edit
          </Button>
          <Popconfirm
            title="Are you sure to delete this record?"
            onConfirm={() => handleDelete(record.id || record.tempId)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link" danger icon={<DeleteOutlined />}>
              Delete
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  const beforeUpload = (file) => {
    setUploadedFile(file);
    addAttachment(file);
    return false;
  };

  const addAttachment = async (file) => {
    try {
      const fileLink = await getFileUrl(
        ASSET_EMPLOYEE_DOCUMENT,
        "Resume",
        file.name.split(".")[1],
        setLoading,
        file
      );
      setFileUrl(fileLink);
      toast.success("File uploaded successfully");
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error("File upload failed");
    }
  };

  const getFormData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
      url: `/api/courses-offered`,
    };
    try {
      const res = await axios(config);
      setData(res.data);
    } catch (err) {
      toast.error("Failed to fetch data");
    } finally {
      setLoading(0);
    }
  };

  useEffect(() => {
    // getFormData();
  }, []);

  return (
    <>
      <Modal
        title={editingRecord ? "Edit Record" : "Add New Record"}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={handleSave}
        okText="Save"
        cancelText="Cancel"
      >
        <Form form={form} layout="vertical">
          <Form.Item label="Year" name="year" rules={[{ required: true }]}>
            <Input type="number" />
          </Form.Item>
          <Form.Item
            label="Number of Courses"
            name="number"
            rules={[{ required: true }]}
          >
            <Input type="number" />
          </Form.Item>
        </Form>
      </Modal>

      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="d-flex flex-column">
          <span className="fw-semibold h5">
            Number of Sanctioned posts year wise during the last five years
          </span>
          <span
            style={{ width: "fit-content" }}
            className="text-white text-nowrap text-center bg-secondary px-3 py-1 shadow-sm rounded-pill"
          >
            Not Started
          </span>
        </div>
        <Button type="primary" onClick={handleAdd} icon={<PlusOutlined />}>
          Add New Record
        </Button>
      </div>

      <Table
        dataSource={data.filter((item) => item.status !== "INACTIVE")}
        columns={columns}
        rowKey="id"
        pagination={false}
      />

      <div className="my-4">
        <span className="h5">Upload Data Template</span>
        <div className="d-flex flex-column my-3 mt-2">
          <a
            className="text-primary mb-3"
            href="/templates/Extended Profile 3.2 Template.xlsx"
            download
          >
            <FileExcelOutlined /> 3.2 Template
          </a>
          <Upload accept=".pdf,.xlsx,.docx,.zip" beforeUpload={beforeUpload}>
            <Button type="primary" icon={<UploadOutlined />}>
              Browse
            </Button>
          </Upload>
        </div>
      </div>

      <div className="d-flex justify-content-end gap-2 mb-3">
        <button
          type="submit"
          onClick={handleSaveAsDraft}
          className="btn btn-primary"
        >
          Save as Draft
        </button>
        <button
          type="button"
          onClick={handleSaveAndSubmitForReview}
          className="btn btn-warning text-dark"
        >
          Submit for Review
        </button>
      </div>
    </>
  );
}
