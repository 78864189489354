import React from "react";

//Components
import StatusBadges from "../../../Components/StatusBadge.jsx";
import FolderTreeSidebar from "./FolderTreeSidebar";
import qif_quantitative_tree_data from "./qif_quantitative_tree_data";
import { IoMdSend } from "react-icons/io";

export default function QifQuantitative() {
  return (
    <div className="mb-4">
      <div className="shadow-xl p-3 border my-2 mb-16 bg-white ">
        <div className="d-flex flex-column gap-4 mb-4">
          <div className="d-flex justify-content-between flex-column mb-3 align-items-start  ">
            <h3 className="text-xl">QIF Quantitative Metric for Engineering</h3>
            <StatusBadges />
          </div>
          <section>
            <FolderTreeSidebar data={qif_quantitative_tree_data} />
          </section>
        </div>
      </div>
      {/* Discussion Section */}
      <div className="shadow p-3 pb-4 border bg-white mt-4 mb-5">
        <p>Team discussion</p>
        <form className="d-flex gap-2 align-items-center">
          <div className="flex-grow-1">
            <input
              type="text"
              className="form-control border-bottom border-0 rounded-0"
              placeholder="Write your message here"
            />
            <hr />
          </div>
          <button type="submit" className="btn btn-primary text-white">
            <IoMdSend />
          </button>
        </form>
      </div>
    </div>
  );
}
