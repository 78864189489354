import { Table } from "antd";
import React from "react";

const columns = [
  {
    title: "Departments",
    dataIndex: "departments",
    key: "departments",
  },
  {
    title: "Not Started",
    dataIndex: "notStarted",
    key: "notStarted",
    render: (text) => (
      <span className={`py-1 px-2 rounded text-secondary`}>{text}</span>
    ),
  },
  {
    title: "In Draft",
    dataIndex: "inDraft",
    key: "inDraft",
    render: (text) => (
      <span className={`py-1 px-2 rounded text-primary`}>{text}</span>
    ),
  },
  {
    title: "Submitted",
    dataIndex: "submitted",
    key: "submitted",
    render: (text) => (
      <span className={`py-1 px-2 rounded text-warning`}>{text}</span>
    ),
  },
  {
    title: "Rejected",
    dataIndex: "rejected",
    key: "rejected",
    render: (text) => (
      <span className={`py-1 px-2 rounded text-danger`}>{text}</span>
    ),
  },
  {
    title: "Approved",
    dataIndex: "approved",
    key: "approved",
    render: (text) => (
      <span className={`py-1 px-2 rounded text-success`}>{text}</span>
    ),
  },
  {
    title: "Evaluated",
    dataIndex: "evaluated",
    key: "evaluated",
    render: (text) => (
      <span className={`py-1 px-2 rounded text-info`}>{text}</span>
    ),
  },
];

const data = [
  {
    key: "1",
    departments: "Kle Technological University",
    notStarted: "99.14% (115/116)",
    inDraft: "0.86% (1/116)",
    submitted: "0.0% (0/116)",
    rejected: "0.0% (0/116)",
    approved: "0.0% (0/116)",
    evaluated: "0.0% (0/116)",
    children: [
      {
        key: "2",
        departments: "MBA",
        notStarted: "100.0% (116/116)",
        inDraft: "0.0% (0/116)",
        submitted: "0.0% (0/116)",
        rejected: "0.0% (0/116)",
        approved: "0.0% (0/116)",
        evaluated: "0.0% (0/116)",
      },
      {
        key: "3",
        departments: "Engineering",
        notStarted: "100.0% (116/116)",
        inDraft: "0.0% (0/116)",
        submitted: "0.0% (0/116)",
        rejected: "0.0% (0/116)",
        approved: "0.0% (0/116)",
        evaluated: "0.0% (0/116)",
      },
      {
        key: "4",
        departments: "Law",
        notStarted: "100.0% (116/116)",
        inDraft: "0.0% (0/116)",
        submitted: "0.0% (0/116)",
        rejected: "0.0% (0/116)",
        approved: "0.0% (0/116)",
        evaluated: "0.0% (0/116)",
      },
      {
        key: "5",
        departments: "Nursing",
        notStarted: "100.0% (116/116)",
        inDraft: "0.0% (0/116)",
        submitted: "0.0% (0/116)",
        rejected: "0.0% (0/116)",
        approved: "0.0% (0/116)",
        evaluated: "0.0% (0/116)",
      },
      {
        key: "6",
        departments: "Hotel management",
        notStarted: "100.0% (116/116)",
        inDraft: "0.0% (0/116)",
        submitted: "0.0% (0/116)",
        rejected: "0.0% (0/116)",
        approved: "0.0% (0/116)",
        evaluated: "0.0% (0/116)",
      },
    ],
  },
];

export default function StatusReport() {
  return (
    <div className="mb-4">
      <div className="shadow-xl p-3 border my-2 mb-16 bg-white ">
        <div className="flex flex-column gap-4 mb-4">
          <div className="flex justify-content-between flex-column mb-3 align-items-start  ">
            <h3>Status Reports</h3>
          </div>

          {/* Table */}
          <Table columns={columns} dataSource={data} />
        </div>
      </div>
    </div>
  );
}
