import React, { useEffect, useState } from "react";
import { Button, Popconfirm, Table, Modal, Form, Input, Select } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import { NAAC } from "../../../../utils/apiConstants";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

const { Option } = Select;
export default function ForCommunication({ setUpdatedData, setLoading }) {
  const [data, setData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [badge, setBadge] = useState("");
  const [form] = Form.useForm();

  const handleAdd = () => {
    form.resetFields();
    setEditingRecord(null);
    setIsModalVisible(true);
  };
  let emp_id = sessionStorage.getItem("employee_id");

  const getFormData = async () => {
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      //  Make this url dynamic after adding the university or college id to the university-profile table
      url: `${NAAC}/contact-persons/1`,
    };
    setLoading(1);
    await axios(config)
      .then((res) => {
        setData(res.data);
        let fetchedData = res.data;
        setBadge(() => {
          if (
            fetchedData?.length > 0 &&
            fetchedData.every((item) => item.status === "REVIEW")
          ) {
            return "REVIEW";
          }
          if (
            fetchedData?.length > 0 &&
            fetchedData?.every((item) => item.status === "DRAFT")
          ) {
            return "DRAFT";
          }
          if (
            fetchedData?.length > 0 &&
            fetchedData?.every((item) => item.status === "APPROVED")
          ) {
            return "APPROVED";
          }
          if (
            fetchedData?.length > 0 &&
            fetchedData?.every((item) => item.status === "REJECTED")
          ) {
            return "REJECTED";
          }
        });
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      })
      .finally(() => {
        setLoading(0);
      });
  };

  const handleEdit = (record) => {
    setEditingRecord(record);
    form.setFieldsValue(record);
    setIsModalVisible(true);
  };
  useEffect(() => {
    getFormData();
  }, []);
  const handleDelete = (identifier) => {
    const updatedData = data.filter((item) => {
      if (item.id === identifier) {
        // For existing items with `id`, mark as INACTIVE
        item.status = "INACTIVE";
        return true; // Keep in the array to send in `toDelete`
      }
      // For new items with `tempId` and no `id`, remove from array
      return item.tempId !== identifier;
    });

    setData(updatedData);
  };
  const handleSave = () => {
    form.validateFields().then((values) => {
      if (editingRecord) {
        // Editing an existing record
        setData(
          data.map((item) =>
            (item.id && item.id === editingRecord.id) ||
            (item.tempId && item.tempId === editingRecord.tempId)
              ? { ...item, ...values, updated_by: emp_id }
              : item
          )
        );
      } else {
        // Adding a new record without `id`
        setData([
          ...data,
          {
            ...values,
            university_profile_id: 1,
            tempId: uuidv4(),
            created_by: emp_id,
            updated_by: emp_id,
            status: "DRAFT",
          },
        ]);
      }
      setIsModalVisible(false);
    });
  };

  const handleSaveASDraft = async (e) => {
    e.preventDefault();

    if (data.length === 0) {
      return toast.error("Please add at least one contact detail");
    }

    // Separate data for backend operations
    const toDelete = data.filter(
      (item) => item.status === "INACTIVE" && item.id
    );
    const toUpdate = data.filter(
      (item) => item.status !== "INACTIVE" && item.id
    );
    const toCreate = data.filter((item) => !item.id);
    setLoading(1);
    const config = {
      method: "post",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: `${NAAC}/contact-persons`,
      data: { toDelete, toUpdate, toCreate },
    };

    await axios(config)
      .then((res) => {
        toast.success("Successfully saved as draft");
        setUpdatedData(uuidv4());
        getFormData(); // Refetch data to update view
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      })
      .finally(() => {
        setLoading(0);
      });
  };

  const columns = [
    {
      title: "Designation",
      dataIndex: "designation",
      key: "designation",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Telephone",
      dataIndex: "telephone",
      key: "telephone",
    },
    {
      title: "Mobile",
      dataIndex: "mobile_no",
      key: "mobile_no",
    },
    {
      title: "Fax",
      dataIndex: "fax_no",
      key: "fax_no",
    },
    {
      title: "Email",
      dataIndex: "email_address",
      key: "email_address",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <>
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => handleEdit(record)}
          >
            Edit
          </Button>
          <Popconfirm
            title="Are you sure to delete this record?"
            onConfirm={() => handleDelete(record.id || record.tempId)}
            okText="Yes"
            cancelText="No"
            placement="topRight"
          >
            <Button type="link" danger icon={<DeleteOutlined />}>
              Delete
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  const handleSaveAndSubmitForReview = async () => {
    if (data.length === 0) {
      return toast.error(
        "Please add at least one contact detail before submitting for review"
      );
    }
    setLoading(1);
    const config = {
      method: "patch",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: `${NAAC}/contact-persons`, // Replace '2' with dynamic id if needed
      data: { data }, // Send all data in the current state
    };

    await axios(config)
      .then((res) => {
        toast.success(res.data.message);
        setUpdatedData(uuidv4());
        getFormData(); // Refresh data to update the view
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      })
      .finally(() => {
        setLoading(0);
      });
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex flex-column gap-2">
          <span className="fw-semibold h5"> For Communication</span>
          <span
            className={`text-white d-inline-block text-center px-4 py-1 shadow-sm text-xs rounded-pill ${
              badge === "DRAFT"
                ? "bg-primary"
                : badge === "REVIEW"
                ? "bg-warning"
                : badge === "REJECTED"
                ? "bg-danger"
                : badge === "APPROVED"
                ? "bg-success"
                : "bg-secondary"
            }`}
            style={{ width: "fit-content" }}
          >
            {badge === "DRAFT"
              ? "In Draft"
              : badge === "REVIEW"
              ? "Submitted for review"
              : badge === "REJECTED"
              ? "Rejected"
              : badge === "APPROVED"
              ? "Approved"
              : "Not Started"}
          </span>
        </div>
        <Button type="primary" icon={<PlusOutlined />} onClick={handleAdd}>
          Add New Row
        </Button>
      </div>
      <div className="table-responsive">
        <Table
          className="table table-bordered"
          dataSource={data.filter((item) => item.status !== "INACTIVE")}
          columns={columns}
          rowKey="id"
          pagination={false}
        />
      </div>
      <Modal
        title={editingRecord ? "Edit Record" : "Add New Record"}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={handleSave}
        okText="Save"
        cancelText="Cancel"
      >
        <Form form={form} layout="vertical">
          <Form.Item
            label="Designation"
            name="designation"
            rules={[{ required: true, message: "Please select a designation" }]}
          >
            <Select>
              <Option value="Principle">Principle</Option>
              <Option value="Vice-Principle">Vice-Principle</Option>
              <Option value="IQAC Co-Ordinator">IQAC Co-Ordinator</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: "Please input the name" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Telephone"
            name="telephone"
            rules={[
              {
                required: true,
                message: "Please input office telephone number",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Mobile"
            name="mobile_no"
            rules={[{ required: true, message: "Please input mobile number" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Fax" name="fax_no">
            <Input />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email_address"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please input a valid email",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>

      <div className="d-flex justify-content-end gap-2 mb-3">
        <button
          type="submit"
          onClick={handleSaveASDraft}
          className="btn btn-primary"
        >
          Save as Draft
        </button>
        <button
          type="button"
          onClick={handleSaveAndSubmitForReview}
          className="btn btn-warning text-dark"
        >
          Submit for Review
        </button>
      </div>
    </>
  );
}
