import { Routes, Route, Navigate } from "react-router-dom";
import NepParameters from "../Pages/NAAC/nep-parameters/NepParameters.jsx";

// Sub Pages of NEP Parameters Page
import Multidisciplinary from "../Pages/NAAC/nep-parameters/sub-pages/Multidisciplinary.jsx";
import AcademicBankOfCredits from "../Pages/NAAC/nep-parameters/sub-pages/AcademicBankOfCredits.jsx";
import SkillDevelopment from "../Pages/NAAC/nep-parameters/sub-pages/SkillDevelopment.jsx";
import AppropriateIntegrationOfIndianKnowledgeSystem from "../Pages/NAAC/nep-parameters/sub-pages/AppropriateIntegrationOfIndianKnowledgeSystem.jsx";
import FocusOnOutcomeBasedEducation from "../Pages/NAAC/nep-parameters/sub-pages/FocusOnOutcomeBasedEducation.jsx";
import DistanceEducation from "../Pages/NAAC/nep-parameters/sub-pages/DistanceEducation.jsx";

export default function NepParametersRoutes() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Navigate to="/registarar/naac/nep-parameters/multidisciplinary" />
        }
      />
      <Route
        path="multidisciplinary"
        element={
          <NepParameters>
            <Multidisciplinary />
          </NepParameters>
        }
      />
      <Route
        path="academic-bank-of-credits"
        element={
          <NepParameters>
            <AcademicBankOfCredits />
          </NepParameters>
        }
      />
      <Route
        path="skill-development"
        element={
          <NepParameters>
            <SkillDevelopment />
          </NepParameters>
        }
      />
      <Route
        path="appropriate-integration-of-indian-knowledge-system"
        element={
          <NepParameters>
            <AppropriateIntegrationOfIndianKnowledgeSystem />
          </NepParameters>
        }
      />
      <Route
        path="focus-on-outcome-based-education"
        element={
          <NepParameters>
            <FocusOnOutcomeBasedEducation />
          </NepParameters>
        }
      />
      <Route
        path="distance-education"
        element={
          <NepParameters>
            <DistanceEducation />
          </NepParameters>
        }
      />
    </Routes>
  );
}
