import React, { useEffect, useState } from "react";
import { Button, Form, Input, Modal, Popconfirm, Select, Table } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { toast } from "react-toastify";
import { NAAC } from "../../../../utils/apiConstants";

const { Option } = Select;

export default function DistinguishedAcademicians({
  setUpdatedData,
  setLoading,
}) {
  const [data, setData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [form] = Form.useForm();
  const [badge, setBadge] = useState("");
  const emp_id = sessionStorage.getItem("employee_id");
  const handleEdit = (record) => {
    setEditingRecord(record);
    form.setFieldsValue(record);
    setIsModalVisible(true);
  };

  const handleAdd = () => {
    form.resetFields();
    setEditingRecord(null);
    setIsModalVisible(true);
  };

  const handleDelete = (identifier) => {
    const updatedData = data.filter((item) => {
      if (item.id === identifier) {
        item.status = "INACTIVE";
        return true;
      }
      return item.tempId !== identifier;
    });

    setData(updatedData);
  };

  const getFormData = async () => {
    setLoading(1);

    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: `${NAAC}/distinguished-academicians/1`,
    };
    await axios(config)
      .then((res) => {
        setData(res.data);
        let fetchedData = res.data;
        setBadge(() => {
          if (
            fetchedData?.length > 0 &&
            fetchedData.every((item) => item.status === "REVIEW")
          ) {
            return "REVIEW";
          }
          if (
            fetchedData?.length > 0 &&
            fetchedData?.every((item) => item.status === "DRAFT")
          ) {
            return "DRAFT";
          }
          if (
            fetchedData?.length > 0 &&
            fetchedData?.every((item) => item.status === "APPROVED")
          ) {
            return "APPROVED";
          }
          if (
            fetchedData?.length > 0 &&
            fetchedData?.every((item) => item.status === "REJECTED")
          ) {
            return "REJECTED";
          }
        });
        setLoading(0);
      })
      .catch((err) => {
        console.error(err);
        toast.error("Something went wrong while fetching data");
      })
      .finally(() => {
        setLoading(0);
      });
  };

  useEffect(() => {
    getFormData();
  }, []);

  const handleSave = () => {
    form.validateFields().then((values) => {
      const newData = editingRecord
        ? data.map((item) =>
            item.id === editingRecord.id
              ? { ...item, ...values, updated_by: emp_id }
              : item
          )
        : [
            ...data,
            {
              ...values,
              tempId: uuidv4(),
              created_by: emp_id,
              updated_by: emp_id,
              university_profile_id: 1,
              status: "DRAFT",
            },
          ];
      setData(newData);
      setIsModalVisible(false);
    });
  };

  const handleSaveAsDraft = async () => {
    if (!data.length) return toast.error("Please add at least one row");
    setLoading(1);

    const toDelete = data.filter(
      (item) => item.status === "INACTIVE" && item.id
    );
    const toUpdate = data.filter(
      (item) => item.status !== "INACTIVE" && item.id
    );
    const toCreate = data.filter((item) => !item.id);

    const config = {
      method: "post",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: `${NAAC}/distinguished-academicians`,
      data: { toDelete, toUpdate, toCreate },
    };

    await axios(config)
      .then(() => {
        toast.success("Data saved as draft");
        getFormData();
        setLoading(0);
        setUpdatedData(uuidv4);
      })
      .catch((err) => {
        console.error(err);
        toast.error("Failed to save as draft");
      })
      .finally(() => {
        setLoading(0);
      });
  };

  const handleSaveAndSubmitForReview = async () => {
    if (data.length === 0) {
      return toast.error(
        "Please add at least one technical staff detail before submitting for review"
      );
    }
    setLoading(1);

    const config = {
      method: "patch",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: `${NAAC}/distinguished-academicians`,
      data: { data },
    };

    await axios(config)
      .then((res) => {
        toast.success(res.data.message);
        getFormData();
        setLoading(0);
        setUpdatedData(uuidv4);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      })
      .finally(() => {
        setLoading(0);
      });
  };
  const calculateTotal = () => {
    let maleTotal = 0;
    let femaleTotal = 0;
    let otherTotal = 0;
    let total = 0;

    data.length > 0 &&
      data.forEach((item) => {
        maleTotal += Number(item?.male);
        femaleTotal += Number(item?.female);
        otherTotal += Number(item?.other);

        total += Number(item?.total);
      });
    return {
      maleTotal,
      femaleTotal,
      otherTotal,
      total,
    };
  };
  const columns = [
    {
      title: "Professor Type",
      dataIndex: "professor_type",
      key: "professor_type",
    },
    {
      title: "Male",
      dataIndex: "male",
      key: "male",
    },
    {
      title: "Female",
      dataIndex: "female",
      key: "female",
    },
    {
      title: "Others",
      dataIndex: "other",
      key: "other",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <div className="d-flex gap-1">
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => handleEdit(record)}
          >
            Edit
          </Button>
          <Popconfirm
            title="Delete this record?"
            onConfirm={() => handleDelete(record.id || record.tempId)}
            okText="Yes"
            cancelText="No"
            placement="topRight"
          >
            <Button type="link" danger icon={<DeleteOutlined />}>
              Delete
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];
  const handleValuesChange = (changedValues, allValues) => {
    // Destructure with default values to handle cases where the input might be undefined
    const { male = 0, female = 0, other = 0 } = allValues;
    // Calculate totals
    const total =
      (Number(male) || 0) + (Number(female) || 0) + (Number(other) || 0);

    // Set total fields in the form
    form.setFieldsValue({
      total: total,
    });
  };
  return (
    <>
      <Modal
        title={
          editingRecord
            ? "Edit Distinguished Academician"
            : "Add New Distinguished Academician"
        }
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={handleSave}
        okText="Save"
        cancelText="Cancel"
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={{ male: 0, female: 0, other: 0, total: 0 }}
          onValuesChange={handleValuesChange}
        >
          <Form.Item
            label="Professor Type"
            name="professor_type"
            rules={[
              { required: true, message: "Please select a professor type" },
            ]}
          >
            <Select placeholder="Select Professor Type">
              <Option value="Emeritus Professor">Emeritus Professor</Option>
              <Option value="Adjunct Professor">Adjunct Professor</Option>
              <Option value="Visiting Professor">Visiting Professor</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Male" name="male">
            <Input type="number" min={0} />
          </Form.Item>
          <Form.Item label="Female" name="female">
            <Input type="number" min={0} />
          </Form.Item>
          <Form.Item label="Others" name="other">
            <Input type="number" min={0} />
          </Form.Item>
          <Form.Item label="Total" name="total">
            <Input type="number" min={0} readOnly />
          </Form.Item>
        </Form>
      </Modal>

      <div className="d-flex justify-content-between align-items-start mb-3">
        <div className="d-flex flex-column gap-2">
          <h5 className="mb-0">Distinguished Academicians</h5>
          <span
            className={`text-white d-inline-block text-center px-4 py-1 shadow-sm text-xs rounded-pill ${
              badge === "DRAFT"
                ? "bg-primary"
                : badge === "REVIEW"
                ? "bg-warning"
                : badge === "REJECTED"
                ? "bg-danger"
                : badge === "APPROVED"
                ? "bg-success"
                : "bg-secondary"
            }`}
            style={{ width: "fit-content" }}
          >
            {badge === "DRAFT"
              ? "In Draft"
              : badge === "REVIEW"
              ? "Submitted for review"
              : badge === "REJECTED"
              ? "Rejected"
              : badge === "APPROVED"
              ? "Approved"
              : "Not Started"}
          </span>
        </div>
        <Button type="primary" onClick={handleAdd} icon={<PlusOutlined />}>
          Add New Row
        </Button>
      </div>

      <div className="table-responsive shadow-sm">
        <Table
          className="table table-bordered"
          dataSource={data.filter((item) => item.status !== "INACTIVE")}
          columns={columns}
          rowKey={(record) => record.id || record.tempId}
          pagination={false}
          summary={() => {
            const { maleTotal, femaleTotal, otherTotal, total } =
              calculateTotal();
            return data.length > 0 ? (
              <Table.Summary.Row>
                <Table.Summary.Cell>
                  <strong>Total</strong>
                </Table.Summary.Cell>

                <Table.Summary.Cell>
                  <strong>{maleTotal}</strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <strong>{femaleTotal}</strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <strong>{otherTotal}</strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <strong>{total}</strong>
                </Table.Summary.Cell>

                <Table.Summary.Cell></Table.Summary.Cell>
              </Table.Summary.Row>
            ) : (
              ""
            );
          }}
        />
      </div>

      <div className="d-flex justify-content-end gap-2 my-3">
        <button className="btn btn-primary" onClick={handleSaveAsDraft}>
          Save as Draft
        </button>
        <button
          className="btn btn-warning"
          onClick={handleSaveAndSubmitForReview}
        >
          Submit for Review
        </button>
      </div>
    </>
  );
}
