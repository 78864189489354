import { NAAC } from "../../../utils/apiConstants";

// const getAllStatus = async () => {
//   try {
//     let response = await fetch(`${NAAC}/get-all-status`);
//     console.log("getAllStatus", await response.json());
//   } catch (error) {
//     console.log("error while fetching statuses");
//   }
// };
// getAllStatus();

export default function profile_stepper_data(data) {
  return [
    // {
    //   id: 1,
    //   name: "Step 1",
    //   href: "/registarar/naac/profile/basic-information",
    //   status: "not-started",
    // },
    // {
    //   id: 2,
    //   name: "Step 2",
    //   href: "/registarar/naac/profile/for-communication",
    //   status: "draft",
    // },
    // // {
    // //   id: 3,
    // //   name: "Step 3",
    // //   href: "/registarar/naac/profile/nature-and-type",
    // //   status: "rejected",
    // // },
    // // {
    // //   id: 4,
    // //   name: "Step 4",
    // //   href: "/registarar/naac/profile/date-of-establishment-and-affiliation-details",
    // //   status: "upcoming",
    // // },
    // // {
    // //   id: 5,
    // //   name: "Step 5",
    // //   href: "/registarar/naac/profile/minority-recognition",
    // //   status: "approved",
    // // },
    // // {
    // //   id: 6,
    // //   name: "Step 6",
    // //   href: "/registarar/naac/profile/source-of-funding",
    // //   status: "upcoming",
    // // },

    // {
    //   id: 7,
    //   name: "Step 7",
    //   href: "/registarar/naac/profile/location-of-the-campus-and-area",
    //   status: "upcoming",
    // },
    // // {
    // //   id: 8,
    // //   name: "Step 8",
    // //   href: "/registarar/naac/profile/affiliated-institution-to-the-university",
    // //   status: "upcoming",
    // // },
    // {
    //   id: 8,
    //   name: "Step 8",
    //   href: "/registarar/naac/profile/details-of-ugc-recognition",
    //   status: "upcoming",
    // },
    // {
    //   id: 9,
    //   name: "Step 9",
    //   href: "/registarar/naac/profile/furnish-details",
    //   status: "upcoming",
    // },
    // {
    //   id: 10,
    //   name: "Step 10",
    //   href: "/registarar/naac/profile/teaching-faculty",
    //   status: "upcoming",
    // },
    // {
    //   id: 11,
    //   name: "Step 11",
    //   href: "/registarar/naac/profile/non-teaching-staff",
    //   status: "upcoming",
    // },

    // {
    //   id: 12,
    //   name: "Step 12",
    //   href: "/registarar/naac/profile/technical-staff",
    //   status: "submitted",
    // },
    // {
    //   id: 13,
    //   name: "Step 13",
    //   href: "/registarar/naac/profile/permanent-teachers",
    //   status: "upcoming",
    // },
    // {
    //   id: 14,
    //   name: "Step 14",
    //   href: "/registarar/naac/profile/temporary-teachers",
    //   status: "upcoming",
    // },
    // {
    //   id: 15,
    //   name: "Step 15",
    //   href: "/registarar/naac/profile/part-time-teachers",
    //   status: "upcoming",
    // },
    // {
    //   id: 16,
    //   name: "Step 16",
    //   href: "/registarar/naac/profile/distinguished-academicians",
    //   status: "upcoming",
    // },
    // {
    //   id: 17,
    //   name: "Step 17",
    //   href: "/registarar/naac/profile/chairs-instituted",
    //   status: "upcoming",
    // },
    // {
    //   id: 18,
    //   name: "Step 18",
    //   href: "/registarar/naac/profile/students-in-current-academic-year",
    //   status: "upcoming",
    // },
    // {
    //   id: 19,
    //   name: "Step 19",
    //   href: "/registarar/naac/profile/integrated-programs",
    //   status: "upcoming",
    // },
    // // {
    // //   id: 20,
    // //   name: "Step 20",
    // //   href: "/registarar/naac/profile/details-on-students-enrollment",
    // //   status: "upcoming",
    // // },
    // {
    //   id: 21,
    //   name: "Step 21",
    //   href: "/registarar/naac/profile/human-resource-development",
    //   status: "upcoming",
    // },
    // {
    //   id: 22,
    //   name: "Step 22",
    //   href: "/registarar/naac/profile/evaluative-reports",
    //   status: "upcoming",
    // },
    // // {
    // //   id: 23,
    // //   name: "Step 23",
    // //   href: "/registarar/naac/profile/date-of-establishment-of-internal-quality-assurance",
    // //   status: "upcoming",
    // // },
    // // {
    // //   id: 24,
    // //   name: "Step 24",
    // //   href: "/registarar/naac/profile/details-regarding-submission-of-annual-quality-assurance-reports",
    // //   status: "upcoming",
    // // },

    {
      id: 1,
      name: "Step 1",
      href: "/registarar/naac/profile/basic-information",
      status: data?.universityStatus,
    },
    {
      id: 2,
      name: "Step 2",
      href: "/registarar/naac/profile/for-communication",
      status: data?.contactPersonsStatus,
    },

    {
      id: 3,
      name: "Step 3",
      href: "/registarar/naac/profile/location-of-the-campus-and-area",
      status: data?.universityLocationStatus,
    },

    {
      id: 4,
      name: "Step 4",
      href: "/registarar/naac/profile/details-of-ugc-recognition",
      status: data?.ugcRecognitionStatus,
    },
    {
      id: 5,
      name: "Step 5",
      href: "/registarar/naac/profile/furnish-details",
      status: data?.collegesUnderUniversityStatus,
    },
    {
      id: 6,
      name: "Step 6",
      href: "/registarar/naac/profile/teaching-faculty",
      status: data?.teachingFacultyStatus,
    },
    {
      id: 7,
      name: "Step 7",
      href: "/registarar/naac/profile/non-teaching-staff",
      status: data?.nonTeachingFacultyStatus,
    },

    {
      id: 8,
      name: "Step 8",
      href: "/registarar/naac/profile/technical-staff",
      status: data?.technicalFacultyStatus,
    },
    {
      id: 9,
      name: "Step 9",
      href: "/registarar/naac/profile/permanent-teachers",
      status: data?.permanentTeachersStatus,
    },
    {
      id: 10,
      name: "Step 10",
      href: "/registarar/naac/profile/temporary-teachers",
      status: data?.temporaryTeachersStatus,
    },
    {
      id: 11,
      name: "Step 11",
      href: "/registarar/naac/profile/part-time-teachers",
      status: data?.partTimeTeachersStatus,
    },
    {
      id: 12,
      name: "Step 12",
      href: "/registarar/naac/profile/distinguished-academicians",
      status: data?.distinguishedAcademiciansStatus,
    },
    {
      id: 13,
      name: "Step 13",
      href: "/registarar/naac/profile/chairs-instituted",
      status: data?.chairsInstitutedStatus,
    },
    {
      id: 14,
      name: "Step 14",
      href: "/registarar/naac/profile/students-in-current-academic-year",
      status: data?.studentsInCurrentAcademicYearStatus,
    },
    {
      id: 15,
      name: "Step 15",
      href: "/registarar/naac/profile/integrated-programs",
      status: data?.integratedProgramsStatus,
    },
    {
      id: 16,
      name: "Step 16",
      href: "/registarar/naac/profile/human-resource-development",
      status: data?.hrDevelopmentCenterStatus,
    },
    {
      id: 17,
      name: "Step 17",
      href: "/registarar/naac/profile/evaluative-reports",
      status: data?.evaluativeReportsStatus,
    },
  ];
}
