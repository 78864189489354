import React from "react";

export default function InputTag(props) {
  return (
    <div className="form-group">
      <label htmlFor={props.name} className="form-label text-muted">
        {props.label}
      </label>
      <div className="mt-1">
        <input
          type={props.type}
          name={props.name}
          id={props.name}
          defaultValue={props.value}
          placeholder={props.placeholder}
          className="form-control"
          {...props}
        />
      </div>
    </div>
  );
}
