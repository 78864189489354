import React from "react";
import Stepper from "../../../Components/Stepper.jsx";
import StatusBadges from "../../../Components/StatusBadge.jsx";
import nep_parameters_stepper_data from "./nep-parameters-stepper-data.js";
import { IoMdSend } from "react-icons/io";

export default function NepParameters({ children }) {
  return (
    <>
      <div className="shadow p-3 border my-2 mb-4 bg-white">
        <div className="d-flex flex-column gap-4">
          <div className="d-flex flex-column my-3 align-items-start align-items-md-center flex-md-row justify-content-between">
            <h3>Institutional preparedness for NEP</h3>
            <StatusBadges />
          </div>

          {/* Stepper Integration */}
          <div className="d-flex justify-content-start justify-content-md-center">
            <Stepper data={nep_parameters_stepper_data()} />
          </div>

          {/* Render Sub-Pages */}
          {children}
        </div>
      </div>

      <div className="shadow p-3 pb-4 border bg-white mt-4 mb-5">
        <p>Team discussion</p>
        <form className="d-flex gap-2 align-items-center">
          <div className="flex-grow-1 form-group">
            <input
              type="text"
              className="form-control border-bottom border-0 rounded-0"
              placeholder="Write your message here"
            />
            <hr />
          </div>
          <button type="submit" className="btn btn-primary text-white">
            <IoMdSend />
          </button>
        </form>
      </div>
    </>
  );
}
