import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Nodata from "../../../Components/NoData/Nodata";
import { ROUTES } from "../../../Router/routerConfig";
import {
  ALL_DATA,
  BASE_URL,
  EMPLOYEE,
  EMPLOYEE_DELETE,
  EMPLOYEE_OTHER_DETAILS,
  EMPLOYEE_PROGRESS,
  EMPLOYEE_SQL,
  EMPLOYEE_RESEND_CREDENTIALS,
} from "../../../utils/apiConstants";
import {
  LOCAL_COLLEGE,
  LOCAL_DEPARTMENT,
  LOCAL_JOBROLES,
} from "../../../utils/LocalStorageConstants";
import getUniversityDetails from "../../../utils/universityDetails.api";
import { Http } from "../../../Services/Services";
import Swal from "sweetalert2";
import { Progress } from "antd";
import Select from "react-select";
import { profile } from "../../../assets/images/reports/profile.png";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useReactToPrint } from "react-to-print";
import { on } from "form-data";
import ModalResendCredentials from "./ModalResendCredentials";
import "./staff.scss";

function ViewStaff({ setLoading, collegeId }) {
  const [universityData, setUniversityData] = useState([]);
  const [search, setSearch] = useState("");

  const navigate = useNavigate();

  // const [reason, setReason] = useState("");

  const local_departments = JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT));

  const [credData, setCredData] = useState([]);

  const [Employee, setEmployee] = useState([]);

  const [employeeData, setEmployeeData] = useState([]);
  const [progressData, setProgressData] = useState([]);

  const [printData, setPrintData] = useState([]);

  const jobPositionOpt = JSON.parse(localStorage.getItem(LOCAL_JOBROLES));

  const [status, setStatus] = useState("ACTIVE");
  const getJobRoles = () => {
    return localStorage.getItem(LOCAL_JOBROLES)
      ? JSON.parse(localStorage.getItem(LOCAL_JOBROLES))
      : [];
  };

  const [modalData, setModalData] = useState(null);

  const [displayData, setDisplayData] = useState([]);

  const [flag, setFlag] = useState(false);

  const [jobRoles, setJobRoles] = useState(getJobRoles);

  const [selectedRole, setSelectedRole] = useState({
    value: "all",
    label: "ALL",
  });

  const changeDir = (dir, key) => {
    navigate(`${dir}/${key}`);
  };

  const getDate = (dateString) => {
    try {
      const dateObject = new Date(dateString);
      const year = dateObject.getFullYear();
      const month = dateObject.getMonth() + 1;
      const day = dateObject.getDate();
      if (!year) return "";
      const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
        .toString()
        .padStart(2, "0")}`;
      return formattedDate;
    } catch (err) {
      return "";
    }
  };

  const getData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${EMPLOYEE_SQL}?college_id=${collegeId}&status=${status}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
    };

    await axios(config)
      .then(async (res) => {
        // setLoading(0);
        // const config1 = {
        //   method: "get",
        //   url: `${EMPLOYEE_OTHER_DETAILS}?college_id=${collegeId}`,
        //   headers: {
        //     Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        //   },
        // };
        // let e = res.data.data;
        // setLoading(1);
        // await axios(config1)
        //   .then((res) => {
        //     let tempData = [];
        //     let otherDetails = res.data.data;

        //     for (let i in e) {
        //       let x = otherDetails.find((s) => s.id == e[i].id);
        //       x.education_array = JSON.parse(x.education_array);
        //       x.experience_array = JSON.parse(x.experience_array);
        //       x.publication_array = JSON.parse(x.publication_array);
        //       x.training_array = JSON.parse(x.training_array);
        //       let combinedData = [];
        //       for (
        //         let j = 0;
        //         j <
        //         Math.max(
        //           x.education_array?.length || 0,
        //           x.experience_array?.length || 0,
        //           x.publication_array?.length || 0,
        //           x.training_array?.length || 0
        //         );
        //         j++
        //       ) {
        //         combinedData.push({
        //           education: x.education_array ? x.education_array[j] : {},
        //           experience: x.experience_array ? x.experience_array[j] : {},
        //           publication: x.publication_array
        //             ? x.publication_array[j]
        //             : {},
        //           training: x.training_array ? x.training_array[j] : {},
        //         });
        //       }
        //       let maxLength = Math.max(
        //         x.education_array?.length || 0,
        //         x.experience_array?.length || 0,
        //         x.publication_array?.length || 0,
        //         x.training_array?.length || 0
        //       );
        //       tempData.push({ ...e[i], combinedData, maxLength });
        //     }
        //     setEmployeeData(tempData);
        //     console.log("data to export - ", tempData);
        //     setLoading(0);
        //   })
        //   .catch((err) => {
        //     setLoading(0);
        //     toast.error(`${err}`);
        //     console.log(err);
        //   });
        setLoading(0);
        setEmployee(res.data.data);
        setFlag((flag) => !flag);
        console.log("emp data - ", res.data.data);
        setEmployeeData(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        toast.error(err.response.data.message);
        console.log(err);
      });
  };

  const getPrintData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${EMPLOYEE}?college_id=${collegeId}&status=${status}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
    };

    await axios(config)
      .then(async (res) => {
        setLoading(0);
        const config1 = {
          method: "get",
          url: `${EMPLOYEE_OTHER_DETAILS}?college_id=${collegeId}`,
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
          },
        };
        let e = res.data.data;
        setLoading(1);
        await axios(config1)
          .then((res) => {
            let tempData = [];
            let otherDetails = res.data.data;

            for (let i in e) {
              let others = {};
              let x = otherDetails.find((s) => s.id == e[i].id);
              others.education_array = JSON.parse(
                x.education_array ? x.education_array : "[]"
              );
              others.experience_array = JSON.parse(
                x.experience_array ? x.experience_array : "[]"
              );
              others.publication_array = JSON.parse(
                x.publication_array ? x.publication_array : "[]"
              );
              others.training_array = JSON.parse(
                x.training_array ? x.training_array : "[]"
              );
              // console.log(others);
              tempData.push({ ...e[i], other_details: others });
            }
            console.log("print data - ", tempData);
            setPrintData(tempData);
            // setEmployeeData(tempData);
            // console.log("data to export - ", tempData);
            setLoading(0);
          })
          .catch((err) => {
            setLoading(0);
            // toast.error(`${err}`);
            toast.error("Something went wrong while fetching print data");
            console.log(err);
          });
        setLoading(0);
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Something went wrong while fetching print data");
        console.log(err);
      });
  };

  const getEmpProgress = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${EMPLOYEE_PROGRESS}?college_id=${collegeId}&status=${status}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
    };

    await axios(config)
      .then(async (res) => {
        console.log("progress - ", res.data.data);
        setProgressData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const deleteEmployee = (reason) => {
  //   console.log(modalData);
  //   var url;
  //   if (modalData.status == "ACTIVE") {
  //     url =
  //       EMPLOYEE_DELETE +
  //       `/${modalData.id}` +
  //       `?status=INACTIVE&reason=${reason}`;
  //   } else if (modalData.status == "INACTIVE") {
  //     url = EMPLOYEE_DELETE + `/${modalData.id}` + `?status=ACTIVE`;
  //   }
  //   console.log("reason - ", reason);
  //   setLoading(1);
  //   Http.post(url)
  //     .then((res) => {
  //       // console.log(res.data);
  //       setLoading(0);
  //       setFlag((flag) => !flag);
  //       toast.success("Employee Deleted Successfully");
  //       getData();
  //       getEmpProgress();
  //       Swal.fire("Deleted!", "Employee Has been deleted", "success");
  //     })
  //     .catch((err) => {
  //       setLoading(0);
  //       toast.error(
  //         err.response.data.message || "Can't able to delete Employee"
  //       );
  //       console.log(err);
  //     });
  //   setLoading(0);
  // };

  const deleteEmployee = (reason, date) => {
    console.log(modalData);
    var url;
    if (modalData.status == "ACTIVE") {
      url =
        EMPLOYEE_DELETE +
        `/${modalData.id}` +
        `?status=INACTIVE&reason=${encodeURIComponent(
          reason
        )}&date=${encodeURIComponent(date)}`;
    } else if (modalData.status == "INACTIVE") {
      url = EMPLOYEE_DELETE + `/${modalData.id}` + `?status=ACTIVE`;
    }
    console.log("reason - ", reason);
    console.log("date - ", date);
    setLoading(1);
    Http.post(url)
      .then((res) => {
        setLoading(0);
        setFlag((flag) => !flag);
        toast.success("Employee Deleted Successfully");
        getData();
        getEmpProgress();
        Swal.fire("Deleted!", "Employee has been deleted", "success");
      })
      .catch((err) => {
        setLoading(0);
        toast.error(
          err.response.data.message || "Can't able to delete Employee"
        );
        console.log(err);
      });
    setLoading(0);
  };

  useEffect(() => {
    getData();
    getEmpProgress();
  }, [status]);

  const selectRole = (id) => {
    if (id == "all") {
      return setEmployeeData(Employee);
    }

    setEmployeeData(Employee.filter((item) => item.role == id));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getUniversityDetails();
        setUniversityData(data);
      } catch (error) {
        console.error(`Error fetching university details: ${error.message}`);
      }
    };

    fetchData();
    // setCountryAndState();
  }, []);

  const getCollegeData = () => {
    return localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;
  };
  const [collegeOpt, setCollegeOpt] = useState(getCollegeData());

  const [departmentOpt, setDepartmentOpt] = useState(
    JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
  );

  const tableRef = useRef();
  const excelRef = useRef();

  const { onDownload } = useDownloadExcel({
    currentTableRef: excelRef.current,
    filename: " Staff Details List ",
    sheet: "Users",
  });

  const PrintRecipt = useReactToPrint({
    content: () => tableRef.current,
  });

  const [data, setData] = useState([]);
  const [placementsData, setPlacementsData] = useState([]);
  const [educationdata, setEducationData] = useState([]);
  const [termsdata, setTermsData] = useState([]);
  const [experiencedata, setExperienceData] = useState([]);
  const [docsdata, setDocsData] = useState([]);
  const [publicadata, setPublicaData] = useState([]);
  const [trainingdata, setTrainingData] = useState([]);
  const [localDepartment, setLocalDepartment] = useState(
    JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
  );

  const getAllData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${EMPLOYEE}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    await axios(config)
      .then((res) => {
        // console.log(res);
        // toast.success("Data Success");
        setData(res.data.data);
        setEmployeeData(res.data.data);
        setLoading(0);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something Went Wrong");
        setLoading(0);
      });
  };

  useEffect(() => {
    // getAllData();
    // getEmpProgress();
  }, []);

  const handlePrint = async () => {
    console.log("hi1");
    await getPrintData();
    console.log("hi2");
    tableRef.current.style.display = "table";

    setTimeout(() => {
      PrintRecipt();
      tableRef.current.style.display = "none";
    }, 10);
  };

  const [score, setScore] = useState(null);

  const fetchProgressData = async () => {
    try {
      const response = await axios.get(
        BASE_URL + "/api/employee/profile-progress/"
      );
      setScore(response.data.score);
    } catch (error) {
      console.error("Error fetching profile progress:", error);
    }
  };

  useEffect(() => {
    fetchProgressData();
  }, []);

  const getCurrentDate = () => {
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${(
      currentDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`;
    return formattedDate;
  };

  const callOnDownload = () => {
    onDownload();
  };

  const getDetailsAndExport = async () => {
    // setLoading(1);
    // console.log('url - ', `${EMPLOYEE_OTHER_DETAILS}/${collegeId}`);
    // const config = {
    //   method: "get",
    //   url: `${EMPLOYEE_OTHER_DETAILS}?college_id=${collegeId}`,
    //   headers: {
    //     Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
    //   },
    // };

    // axios(config)
    //   .then((res) => {
    //     setLoading(0);
    //     let tempData = [];
    //     let otherDetails = res.data.data;

    //     for(let i in employeeData) {
    //       let x = otherDetails.find((s) => s.id == employeeData[i].id);
    //       x.education_array = JSON.parse(x.education_array);
    //       x.experience_array = JSON.parse(x.experience_array);
    //       x.publication_array = JSON.parse(x.publication_array);
    //       x.training_array = JSON.parse(x.training_array);
    //       let combinedData = [];
    //       for (let j = 0; j < Math.max(
    //         x.education_array?.length || 0,
    //         x.experience_array?.length || 0,
    //         x.publication_array?.length || 0,
    //         x.training_array?.length || 0
    //       ); j++) {
    //         combinedData.push({
    //           education: x.education_array ? x.education_array[j] : {},
    //           experience: x.experience_array ? x.experience_array[j] : {},
    //           publication: x.publication_array ? x.publication_array[j] : {},
    //           training: x.training_array ? x.training_array[j] : {}
    //         });
    //       }
    //       let maxLength = Math.max(
    //         x.education_array?.length || 0,
    //         x.experience_array?.length || 0,
    //         x.publication_array?.length || 0,
    //         x.training_array?.length || 0
    //       );
    //       tempData.push({...employeeData[i], combinedData, maxLength});
    //     }
    //     setEmployeeData(tempData);
    //     console.log('data to export - ', tempData);
    //   })
    //   .catch((err) => {
    //     setLoading(0);
    //     toast.error(err);
    //     console.log(err);
    //   });

    callOnDownload();
  };
  // console.log(
  //   "female",
  //   employeeData?.map((item) => item.gender)
  // );
  const totalEmployees = employeeData.length;
  const totalMales =
    employeeData?.filter((item) => item.gender?.toLowerCase() == "male")
      .length || 0;
  const totalFemales =
    employeeData?.filter((item) => item.gender?.toLowerCase() == "female")
      .length || 0;
  const totalOthers =
    employeeData?.filter((item) => item.gender?.toLowerCase() == "others")
      .length || 0;
  return (
    <div className="ViewStaff">
      <>
        <ModalResendCredentials
          empData={employeeData}
          displayData={displayData}
          setDisplayData={setDisplayData}
          setLoading={setLoading}
        />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <form action="" method="POST">
                {/* start page title */}
                <div className="row">
                  <div className="col-12">
                    <div className="page-title-box d-flex align-items-center justify-content-between">
                      <h4 className="mb-0">View Staff </h4>
                      <div className="page-title-right">
                        <ol className="breadcrumb m-0">
                          <li className="breadcrumb-item">
                            <a href="javascript: void(0);">Human Resource</a>
                          </li>
                          <li className="breadcrumb-item active">
                            {" "}
                            View Staff{" "}
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end page title */}
                <div className="row">
                  <div className="col-xl-12">
                    <div className="card">
                      <div className="card-body">
                        <h2 className="card-title">Select Criteria</h2>
                        <br />
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="validationCustom01">
                                Designation
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <select
                                id="role"
                                name="role"
                                className="form-control"
                                autoComplete="off"
                                onChange={(e) => selectRole(e.target.value)}
                              >
                                <option value="">Select</option>
                                <option value="all">ALL</option>

                                {jobRoles?.map((data, key) => {
                                  return (
                                    <option value={data.id}>{data.name}</option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="">Status</label>
                              <select
                                className="form-control"
                                value={status}
                                onChange={(e) => setStatus(e.target.value)}
                              >
                                <option value="ACTIVE">ACTIVE</option>
                                <option value="INACTIVE">INACTIVE</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* end card */}
                  </div>
                </div>
              </form>
              <div className="row">
                <div className="col-xl-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-6">
                          <h2 className="card-title">
                            Staff List (T - {totalEmployees}, M -{totalMales}, F
                            -{totalFemales}, O - {totalOthers}
                            {/* {Math.abs(
                              parseInt(totalEmployees) -
                                parseInt(totalMales) -
                                parseInt(totalFemales)
                            )} */}
                            )
                          </h2>
                        </div>
                        <div className="col-md-6">
                          <button
                            className="btn btn-nex ml-3 float-right"
                            onClick={handlePrint}
                          >
                            PDF
                          </button>
                          <button
                            className="btn btn-nex ml-3 float-right"
                            onClick={onDownload}
                          >
                            Excel
                          </button>
                          <button
                            className="btn btn-nex float-right"
                            data-toggle="modal"
                            data-target="#ModalResendCredentials"
                            onClick={() => setDisplayData(employeeData)}
                          >
                            Send Credentials
                          </button>
                        </div>

                        <div className="col-md-6 mt-4">
                          <input
                            type="text"
                            id="myFilter"
                            className="form-control"
                            placeholder="Search Employee by name"
                            value={search}
                            onChange={(e) => {
                              setSearch(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <br />
                      <div className="row" id="myItems">
                        {employeeData && employeeData.length !== 0 ? (
                          employeeData
                            ?.filter((post) => {
                              if (search === "") {
                                return post;
                              } else if (
                                (
                                  post.first_name.toLowerCase() +
                                  " " +
                                  post.last_name.toLowerCase()
                                ).includes(search.toLowerCase())
                              ) {
                                return post;
                              }
                            })
                            .filter((s) => s.id != 1)
                            .map((data, key) => {
                              // s.id != 1 to remove super admin
                              return (
                                <>
                                  <div
                                    className="col-lg-6 col-xl-4 col-md-6 col-sm-12 crd"
                                    key={key}
                                  >
                                    <div className="card card-default p-2">
                                      <a
                                        href=""
                                        className="media text-secondary"
                                        data-toggle="modal"
                                        data-target={`#modal-contact-open`}
                                        onClick={() => setModalData(data)}
                                      >
                                        <img
                                          src={`${
                                            data?.photo
                                              ? data?.photo
                                              : data?.gender == "female" ||
                                                data?.gender == "Female" ||
                                                data?.gender == "FEMALE"
                                              ? "../../../assets/images/reports/profile.png"
                                              : "../../../assets/images/reports/profile.png"
                                          }`}
                                          className="mr-3 img-fluid rounded-circle"
                                          alt="Avatar Image"
                                          width="30%"
                                          style={{ aspectRatio: "1/1" }}
                                        />
                                        <div className="media-body">
                                          <h5 className="mt-0 mb-2 text-dark">
                                            {data.first_name +
                                              " " +
                                              data.last_name}
                                          </h5>
                                          <ul className="list-unstyled text-smoke text-smoke">
                                            <li className="d-flex">
                                              <i className="mdi mdi-map mr-1" />
                                              <span>
                                                {
                                                  local_departments.find(
                                                    (s) =>
                                                      s.id ===
                                                      data.department_id
                                                  )?.name
                                                }
                                              </span>
                                            </li>
                                            <li className="d-flex">
                                              <i className="mdi mdi-account mr-1" />
                                              <span>
                                                {
                                                  jobPositionOpt?.find(
                                                    (s) => s?.id == data?.role
                                                  )?.name
                                                }
                                              </span>
                                            </li>
                                            <li className="d-flex">
                                              <i className="mdi mdi-phone mr-1" />
                                              <span>{data.phone}</span>
                                            </li>
                                            {data?.status == "INACTIVE" ? (
                                              <li>
                                                <i className="mdi mdi-close-circle mr-1 text-danger" />
                                                <span className="text-danger">
                                                  {data?.inactive_reason}
                                                </span>
                                              </li>
                                            ) : (
                                              <></>
                                            )}
                                          </ul>
                                        </div>
                                        <Progress
                                          className="mt-4"
                                          type="dashboard"
                                          percent={
                                            progressData.find(
                                              (s) => s.id == data?.id
                                            )?.score
                                          }
                                          size={"small"}
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </>
                              );
                            })
                        ) : (
                          <div className="col-12">
                            <Nodata titleTop={"No Employees Added"} />
                          </div>
                        )}

                        {/* MODAL START */}
                        {modalData ? (
                          <div
                            className="modal fade"
                            id={`modal-contact-open`}
                            tabIndex={-1}
                            role="dialog"
                            aria-labelledby="exampleModalLabel"
                            aria-hidden="true"
                          >
                            <div
                              className="modal-dialog modal-dialog-centered modal-lg"
                              role="document"
                            >
                              <div className="modal-content">
                                <div className="modal-header justify-content-end border-bottom-0">
                                  <button
                                    type="submit"
                                    className="btn-edit-icon btn-primary btn"
                                    onClick={() => {
                                      changeDir(
                                        ROUTES.HR.Employee.EditStaffRoute,
                                        modalData.id
                                      );
                                    }}
                                    data-dismiss="modal"
                                    aria-label="Close"
                                  >
                                    <i className="mdi mdi-pencil" />
                                  </button>
                                  &nbsp;&nbsp; &nbsp;
                                  <button
                                    type="button"
                                    className=" btn btn-danger btn-close-icon"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                  >
                                    <i className="mdi mdi-close" />
                                  </button>
                                </div>
                                <div className="modal-body pt-0">
                                  <div className="row no-gutters">
                                    <div className="col-md-6">
                                      <div className="profile-content-left px-4">
                                        <div className="card text-center px-0 border-0">
                                          <div className="card-img mx-auto">
                                            <img
                                              className="rounded-circle"
                                              src={`${
                                                modalData?.photo
                                                  ? modalData?.photo
                                                  : "../../../assets/images/reports/profile.png"
                                              }`}
                                              alt="user image"
                                              width="30%"
                                              style={{ aspectRatio: "1/1" }}
                                            />
                                          </div>
                                          <div className="card-body">
                                            <h4 className="py-2">
                                              {modalData?.first_name +
                                                " " +
                                                modalData?.last_name}
                                            </h4>
                                            <p>
                                              {
                                                jobPositionOpt?.find(
                                                  (s) => s?.id == modalData.role
                                                )?.name
                                              }
                                            </p>
                                          </div>
                                        </div>
                                        {/* {sessionStorage.getItem("role") ==
                                        "SUPERADMIN" ? (
                                          <button
                                            data-dismiss="modal"
                                            aria-label="Close"
                                            className="btn btn-outline-danger w-100"
                                            onClick={() => {
                                              Swal.fire({
                                                title: "Are you sure?",
                                                text: "You won't be able to revert this!",
                                                icon: "warning",
                                                showCancelButton: true,
                                                confirmButtonColor: "#3085d6",
                                                cancelButtonColor: "#d33",
                                                confirmButtonText:
                                                  modalData.status == "ACTIVE"
                                                    ? "Yes, delete it!"
                                                    : "Yes, Turn to Active !",
                                                html:
                                                  modalData.status == "ACTIVE"
                                                    ? `<select id="reason" class="swal2-select">
                                                      <option value="">Select a reason</option>
                                                      <option value="Retirement">Retirement</option>
                                                      <option value="Resigned">Resignation</option>
                                                    </select>`
                                                    : ``,
                                                preConfirm: () => {
                                                  const selectElement =
                                                    document.getElementById(
                                                      "reason"
                                                    );
                                                  const selectedReason =
                                                    selectElement
                                                      ? selectElement.value
                                                      : "";
                                                  if (
                                                    modalData.status ==
                                                      "ACTIVE" &&
                                                    !selectedReason
                                                  ) {
                                                    Swal.showValidationMessage(
                                                      "Please select a reason"
                                                    );
                                                  }
                                                  return modalData.status ==
                                                    "ACTIVE"
                                                    ? selectedReason
                                                    : true;
                                                },
                                              }).then((result) => {
                                                if (result.isConfirmed) {
                                                  // setReason(result.value);
                                                  deleteEmployee(result.value);
                                                }
                                              });
                                            }}
                                          >
                                            {modalData?.status == "ACTIVE"
                                              ? "Delete Employee"
                                              : "Turn Employee Status to Active"}
                                          </button>
                                        ) : null} */}
                                        {sessionStorage.getItem("role") ==
                                        "SUPERADMIN" ? (
                                          <button
                                            data-dismiss="modal"
                                            aria-label="Close"
                                            className="btn btn-outline-danger w-100"
                                            onClick={() => {
                                              Swal.fire({
                                                title: "Are you sure?",
                                                text: "You won't be able to revert this!",
                                                icon: "warning",
                                                showCancelButton: true,
                                                confirmButtonColor: "#3085d6",
                                                cancelButtonColor: "#d33",
                                                confirmButtonText:
                                                  modalData.status == "ACTIVE"
                                                    ? "Yes, delete it!"
                                                    : "Yes, Turn to Active!",
                                                html:
                                                  modalData.status == "ACTIVE"
                                                    ? `<select id="reason" class="swal2-select">
              <option value="">Select a reason</option>
              <option value="Retirement">Retirement</option>
              <option value="Resigned">Resignation</option>
            </select>
            <br />
            <input type="date" id="date" class="swal2-input" placeholder="Select a date" value="">`
                                                    : ``,
                                                preConfirm: () => {
                                                  const selectElement =
                                                    document.getElementById(
                                                      "reason"
                                                    );
                                                  const selectedReason =
                                                    selectElement
                                                      ? selectElement.value
                                                      : "";
                                                  const dateElement =
                                                    document.getElementById(
                                                      "date"
                                                    );
                                                  const selectedDate =
                                                    dateElement
                                                      ? dateElement.value
                                                      : "";

                                                  if (
                                                    modalData.status ==
                                                      "ACTIVE" &&
                                                    !selectedReason
                                                  ) {
                                                    Swal.showValidationMessage(
                                                      "Please select a reason"
                                                    );
                                                  } else if (
                                                    modalData.status ==
                                                      "ACTIVE" &&
                                                    !selectedDate
                                                  ) {
                                                    Swal.showValidationMessage(
                                                      "Please select a date"
                                                    );
                                                  }

                                                  return modalData.status ==
                                                    "ACTIVE"
                                                    ? {
                                                        reason: selectedReason,
                                                        date: selectedDate,
                                                      }
                                                    : true;
                                                },
                                              }).then((result) => {
                                                if (result.isConfirmed) {
                                                  const { reason, date } =
                                                    result.value || {};
                                                  deleteEmployee(reason, date);
                                                }
                                              });
                                            }}
                                          >
                                            {modalData?.status == "ACTIVE"
                                              ? "Delete Employee"
                                              : "Turn Employee Status to Active"}
                                          </button>
                                        ) : null}
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="contact-info px-4">
                                        <h4 className="mb-1">Details</h4>
                                        <p className="text-dark font-weight-medium pt-4 mb-2">
                                          Department
                                        </p>
                                        <p>
                                          {
                                            local_departments.find(
                                              (s) =>
                                                s.id ===
                                                modalData?.department_id
                                            )?.name
                                          }
                                        </p>
                                        <p className="text-dark font-weight-medium pt-4 mb-2">
                                          Email address
                                        </p>
                                        <p>{modalData?.email}</p>
                                        <p className="text-dark font-weight-medium pt-4 mb-2">
                                          Phone Number
                                        </p>
                                        <p>{modalData?.phone}</p>
                                        <p className="text-dark font-weight-medium pt-4 mb-2">
                                          Date of Birth
                                        </p>
                                        <p>{modalData?.dob?.split("T")[0]}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}

                        {/* MODAL END */}
                      </div>
                      <br />
                      <br />
                    </div>
                  </div>
                  {/* end card */}
                </div>

                {/* <table
                  id=""
                  // ref={tableRef}
                  className="table table-bordered"
                  style={{
                    borderCollapse: "collapse",
                    borderSpacing: 0,
                    width: "100%",
                    display: "none",
                  }}
                >
                  <tbody>
                    {printData && printData?.length === 0 ? (
                      <tr>
                        <td colSpan={10}>
                          <Nodata />
                        </td>
                      </tr>
                    ) : (
                      printData
                        ?.filter((post) => {
                          if (search === "") {
                            return post;
                          } else if (
                            (
                              post.first_name.toLowerCase() +
                              " " +
                              post.last_name.toLowerCase()
                            ).includes(search.toLowerCase())
                          ) {
                            return post;
                          }
                        })
                        .map((data, key) => (
                          <tr key={key}>
                            <thead>
                              
                              <tr>
                                <th colSpan="4" className="text-bold">
                                  <p className="text-dark">
                                    Printed On - {getCurrentDate()}
                                  </p>
                                  <h4 className="mt-1 mb-2 ml-4 text-primary">
                                    <img
                                      className="profile-user-img img-responsive mx-auto"
                                      src="../../../assets/images/logo.png"
                                      width="100px"
                                      height="80px"
                                      style={{ aspectRatio: "1/1" }}
                                    />
                                    <span className="ml-3">
                                      {
                                        collegeOpt?.find(
                                          (s) => s.id === data?.college_id
                                        )?.name
                                      }
                                    </span>
                                  </h4>
                                </th>
                                <th>
                                  <h5>STAFF PROFILE</h5>
                                  <br />
                                  <h3>
                                    {data?.first_name + " " + data?.last_name}
                                  </h3>
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              <tr>
                                <th colSpan="4">
                                  <h5 className="bg-dark text-light">
                                    Personal Information
                                  </h5>
                                </th>
                              </tr>

                              <tr>
                                <th width="10%">Staff Name :</th>
                                <td width="10%">
                                  {data?.first_name + " " + data?.last_name}
                                </td>
                                <th width="10%">Staff Id :</th>
                                <td width="10%">{data?.staff_id}</td>
                                <td rowSpan="9" width="10%">
                                  <img
                                    className="profile-user-img img-responsive mx-auto d-block mt-5"
                                    src={
                                      data?.photo
                                        ? data?.photo
                                        : "../../../assets/images/reports/profile.png"
                                    }
                                    width="230px"
                                    height="200px"
                                    style={{
                                      aspectRatio: "1/1",
                                      marginBottom: "60px",
                                      marginTop: "80px",
                                    }}
                                    alt="profile_photo"
                                  />
                                  <h3 className="text-center">
                                    {data?.first_name + " " + data?.last_name}
                                  </h3>
                                  <br />
                                  <div className="mb-3">
                                    <h5>Profile Completion Progress</h5>
                                    {score !== null ? (
                                      <Progress percent={score} />
                                    ) : (
                                      <p>Loading...</p>
                                    )}
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <th>Mother Name :</th>
                                <td>{data?.mother_name}</td>
                                <th>Father Name :</th>
                                <td>{data?.father_name}</td>
                              </tr>

                              <tr>
                                <th>Marital Status :</th>
                                <td>{data?.marital_status}</td>
                                <th>Date Of Birth :</th>
                                <td>
                                  {data?.dob && data?.dob.substring(0, 10)}
                                </td>
                              </tr>

                              <tr>
                                <th>Father Name :</th>
                                <td>{data?.father_name}</td>
                                <th>Mother Name :</th>
                                <td>{data?.mother_name}</td>
                              </tr>

                              <tr>
                                <th>Gender :</th>
                                <td>{data?.gender}</td>
                                <th>Blood Group :</th>
                                <td>{data?.blood_grp}</td>
                              </tr>

                              <tr>
                                <th>Email :</th>
                                <td>{data?.email}</td>
                                <th>Nationality :</th>
                                <td>{data?.nationality}</td>
                              </tr>

                              <tr>
                                <th>State :</th>
                                <td>{data?.state}</td>
                                <th>District :</th>
                                <td>{data?.district}</td>
                              </tr>

                              <tr>
                                <th>City :</th>
                                <td>{data?.city}</td>
                                <th>Religion :</th>
                                <td>{data?.religion}</td>
                              </tr>

                              <tr>
                                <th>Caste :</th>
                                <td>{data?.caste}</td>
                                <th>Category :</th>
                                <td>{data?.category}</td>
                              </tr>
                              <br />
                              <tr>
                                <th colSpan="4">
                                  <h5 className="bg-dark text-light">
                                    Contact Information
                                  </h5>
                                </th>
                              </tr>

                              <tr>
                                <th> Mobile No : </th>
                                <td>{data?.mobile}</td>
                              </tr>
                              <tr>
                                <th>Email :</th>
                                <td>{data?.email}</td>
                              </tr>

                              <tr>
                                <th>Contact No :</th>
                                <td>{data?.phone}</td>
                              </tr>
                              <br />
                              <tr>
                                <th colSpan="4">
                                  <h5 className="bg-dark text-light">
                                    Address Information
                                  </h5>
                                </th>
                              </tr>

                              <tr>
                                <th> Current Address : </th>
                                <td>{data?.current_address}</td>
                                <th>Permanent Address :</th>
                                <td>{data?.permanent_address}</td>
                              </tr>
                              <br />
                              <tr>
                                <th colSpan="4" style={{ border: "none" }}>
                                  <h5 className="bg-dark text-light">
                                    Education Information
                                  </h5>
                                </th>
                              </tr>

                              {data?.other_details?.education_array &&
                                data?.other_details?.education_array?.map(
                                  (education, index) => (
                                    <>
                                      <tr>
                                        <th> University : </th>
                                        <td>{education?.university_name}</td>
                                        <th> Year :</th>
                                        <td>
                                          {education?.from_year
                                            ? education?.from_year + "-"
                                            : ""}
                                          {education?.to_year}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th>Education Type :</th>
                                        <td>
                                          {education?.education_type === "other"
                                            ? education?.other_education_type
                                            : education?.education_type}
                                        </td>
                                        <th>Grade :</th>
                                        <td>
                                          {education?.performance_score
                                            ? education?.performance_score
                                            : "__"}
                                        </td>
                                      </tr>
                                    </>
                                  )
                                )}

                              <br />

                              <br />
                              <tr>
                                <th colSpan="4">
                                  <h5 className="bg-dark text-light">
                                    Experience Information
                                  </h5>
                                </th>
                              </tr>

                              {data?.other_details?.experience_array &&
                                data?.other_details?.experience_array?.map(
                                  (experience, index) => (
                                    <>
                                      <tr>
                                        <th> Employer : </th>
                                        <td>{experience?.employer}</td>
                                        <th>Job Title :</th>
                                        <td>{experience?.job_title}</td>
                                      </tr>
                                      <tr>
                                        <th>Year :</th>
                                        <td>
                                          {experience?.from &&
                                            experience?.from.substring(
                                              0,
                                              10
                                            )}{" "}
                                          {"->"}{" "}
                                          {experience?.to &&
                                            experience?.to.substring(
                                              0,
                                              10
                                            )}{" "}
                                          ({experience.duration})
                                        </td>
                                        <th>salary :</th>
                                        <td>
                                          {experience?.salary
                                            ? experience?.salary
                                            : "__"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th> Country / Region : </th>
                                        <td>
                                          {" "}
                                          {experience?.region
                                            ? experience?.region
                                            : "__"}
                                        </td>
                                        <th> Remark : </th>
                                        <td>
                                          {" "}
                                          {experience?.remark
                                            ? experience?.remark
                                            : "__"}
                                        </td>
                                      </tr>
                                    </>
                                  )
                                )}

                              <br />
                              <tr>
                                <th colSpan="4" style={{ border: "none" }}>
                                  <h5 className="bg-dark text-light">
                                    Training Information
                                  </h5>
                                </th>
                              </tr>

                              {data?.other_details?.training_array &&
                                data?.other_details?.training_array?.map(
                                  (training, index) => (
                                    <>
                                      <tr>
                                        <th> Course : </th>
                                        <td>{training?.course}</td>
                                      </tr>
                                      <tr>
                                        <th>Date :</th>
                                        <td>
                                          {training?.date &&
                                            training?.date.substring(0, 10)}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th>Result :</th>
                                        <td>
                                          {training?.result
                                            ? training?.result
                                            : "__"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th>Trainer :</th>
                                        <td>
                                          {training?.trainer_id
                                            ? training?.trainer_id
                                            : "__"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th>Remark :</th>
                                        <td>
                                          {training?.remark
                                            ? training?.remark
                                            : "__"}
                                        </td>
                                      </tr>
                                    </>
                                  )
                                )}

                              <br />
                              <tr>
                                <th colSpan="4">
                                  <h5 className="bg-dark text-light">
                                    Document Information
                                  </h5>
                                </th>
                              </tr>

                              {docsdata &&
                                docsdata
                                  ?.filter(
                                    (document) =>
                                      document.employee_id === data.id
                                  )
                                  ?.map((document, index) => (
                                    <>
                                      <tr>
                                        <th>Document :</th>
                                        <td>{document?.document_title}</td>
                                        <th>Document Type :</th>
                                        <td>{document?.document_type}</td>
                                      </tr>
                                      <tr>
                                        <th>Valid From :</th>
                                        <td>
                                          {document?.valid_from &&
                                          document?.valid_from.substring(0, 10)
                                            ? "Validation"
                                            : "Validation Upto"}
                                        </td>
                                        <th>Valid To :</th>
                                        <td>
                                          {document?.valid_from
                                            ? document?.valid_from &&
                                              document?.valid_from.substring(
                                                0,
                                                10
                                              ) +
                                                " -> " +
                                                (document?.valid_to
                                                  ? document?.valid_to &&
                                                    document?.valid_to.substring(
                                                      0,
                                                      10
                                                    )
                                                  : "Not Available")
                                            : "Not Available"}
                                        </td>
                                      </tr>
                                    </>
                                  ))}

                              <br />
                              <tr>
                                <th colSpan="4" style={{ border: "none" }}>
                                  <h5 className="bg-dark text-light">
                                    Placement Information
                                  </h5>
                                </th>
                              </tr>

                              <tr>
                                {trainingdata &&
                                  trainingdata
                                    ?.filter(
                                      (training) =>
                                        training.employee_id === data.id
                                    )
                                    ?.map((training, index) => (
                                      <>
                                        <tr>
                                          <th> Course : </th>
                                          <td>{training?.course}</td>
                                          <th>Date :</th>
                                          <td>
                                            {training?.date &&
                                              training.date.substring(0, 10)}
                                          </td>
                                        </tr>
                                        <tr>
                                          <th>Result :</th>
                                          <td>
                                            {training?.result
                                              ? training?.result
                                              : "__"}
                                          </td>
                                          <th>Trainer :</th>
                                          <td>
                                            {training?.trainer_id
                                              ? training?.trainer_id
                                              : "__"}
                                          </td>
                                        </tr>
                                      </>
                                    ))}
                              </tr>

                              <br />
                              <tr>
                                <th colSpan="4">
                                  <h5 className="bg-dark text-light">
                                    Publication Information
                                  </h5>
                                </th>
                              </tr>

                              {data?.other_details?.publication_array &&
                                data?.other_details?.publication_array?.map(
                                  (publication, index) => (
                                    <>
                                      <tr>
                                        <th>Publication :</th>
                                        <td>{publication?.name}</td>
                                        <th>Date :</th>
                                        <td>
                                          {publication?.date &&
                                            publication?.date.substring(0, 10)}
                                        </td>
                                      </tr>
                                    </>
                                  )
                                )}
                            </tbody>
                          </tr>
                        ))
                    )}
                  </tbody>
                </table> */}

                <div style={{ display: "none" }}>
                  <section ref={tableRef}>
                    <div className="col-sm-12">
                      {printData && printData?.length === 0 ? (
                        <tr>
                          <td colSpan={10}>
                            <Nodata />
                          </td>
                        </tr>
                      ) : (
                        printData
                          ?.filter((post) => {
                            if (search === "") {
                              return post;
                            } else if (
                              (
                                post.first_name.toLowerCase() +
                                " " +
                                post.last_name.toLowerCase()
                              ).includes(search.toLowerCase())
                            ) {
                              return post;
                            }
                          })
                          .map((data, key) => (
                            <table className="table staff-print">
                              <thead>
                                <tr className="row">
                                  <th className="text-bold col-sm-9">
                                    <p className="text-dark ml-2">
                                      Printed On - {getCurrentDate()}
                                    </p>
                                    <h3 className="mt-5 mb-4 ml-4 text-primary">
                                      <img
                                        className="profile-user-img img-responsive mx-auto"
                                        src="../../../assets/images/Nexenstial Logo.jpg"
                                        width="100px"
                                        height="80px"
                                        style={{ aspectRatio: "1/1" }}
                                      />
                                      <span className="ml-3 print-staff-details">
                                        {
                                          collegeOpt?.find(
                                            (s) => s.id === data?.college_id
                                          )?.name
                                        }
                                      </span>
                                    </h3>
                                  </th>
                                  <th className="col-sm-3">
                                    <h2
                                      style={{
                                        marginTop: "25px",
                                        marginLeft: "20px",
                                      }}
                                    >
                                      STAFF PROFILE
                                    </h2>
                                    <br />
                                    <h3 className="print-staff-details mt-2 ml-3">
                                      {data?.first_name + " " + data?.last_name}
                                    </h3>
                                  </th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr className="row">
                                  <td className="col-sm-8">
                                    <h4 className="text-center my-3 print-staff-details">
                                      PERSONAL INFORMATION
                                    </h4>

                                    <br />
                                    <tr className="row ml-5">
                                      <td className="col-sm-6">
                                        <p style={{ margin: "0 0 0 14px" }}>
                                          <strong>Staff Name :</strong>{" "}
                                          <span className="ml-3">
                                            {data?.first_name +
                                              " " +
                                              data?.last_name}
                                          </span>
                                        </p>
                                      </td>
                                      <td className="col-sm-6">
                                        <p style={{ margin: "0 0 0 14px" }}>
                                          <strong>Staff ID : </strong>
                                          <span className="ml-3">
                                            {data?.staff_id}
                                          </span>
                                        </p>
                                      </td>
                                    </tr>

                                    <br />
                                    <tr className="row ml-5">
                                      <td className="col-sm-6">
                                        <p style={{ margin: "0 0 0 14px" }}>
                                          <strong>Phone Number: </strong>
                                          <span className="ml-3">
                                            {data?.phone}
                                          </span>
                                        </p>
                                      </td>
                                      <td className="col-sm-6">
                                        <p style={{ margin: "0 0 0 14px" }}>
                                          <strong>Email : </strong>
                                          <span className="ml-3">
                                            {data?.email}
                                          </span>
                                        </p>
                                      </td>
                                    </tr>

                                    <br />
                                    <tr className="row ml-5">
                                      <td className="col-sm-6">
                                        <p style={{ margin: "0 0 0 14px" }}>
                                          <strong>Date of Birth : </strong>
                                          <span className="ml-3">
                                            {data?.dob &&
                                              data?.dob.substring(0, 10)}
                                          </span>
                                        </p>
                                      </td>
                                      <td className="col-sm-6">
                                        <p style={{ margin: "0 0 0 14px" }}>
                                          <strong>Gender : </strong>
                                          <span className="ml-3">
                                            {data?.gender}
                                          </span>
                                        </p>
                                      </td>
                                    </tr>

                                    <br />
                                    <tr className="row ml-5">
                                      <td className="col-sm-6">
                                        <p style={{ margin: "0 0 0 14px" }}>
                                          <strong>Mother Name : </strong>
                                          <span className="ml-3">
                                            {data?.mother_name}
                                          </span>
                                        </p>
                                      </td>
                                      <td className="col-sm-6">
                                        <p style={{ margin: "0 0 0 14px" }}>
                                          <strong>Father Name : </strong>
                                          <span className="ml-3">
                                            {data?.father_name}
                                          </span>
                                        </p>
                                      </td>
                                    </tr>

                                    <br />
                                    <tr className="row ml-5">
                                      <td className="col-sm-6">
                                        <p style={{ margin: "0 0 0 14px" }}>
                                          <strong>Role : </strong>
                                          <span className="ml-3">
                                            {data?.role}
                                          </span>
                                        </p>
                                      </td>
                                      <td className="col-sm-6">
                                        <p style={{ margin: "0 0 0 14px" }}>
                                          <strong>Employee Category : </strong>
                                          <span className="ml-3">
                                            {data?.employee_category}
                                          </span>
                                        </p>
                                      </td>
                                    </tr>

                                    <br />
                                    <tr className="row ml-5">
                                      <td className="col-sm-6">
                                        <p style={{ margin: "0 0 0 14px" }}>
                                          <strong>Faculty : </strong>
                                          <span className="ml-3">
                                            {
                                              collegeOpt?.find(
                                                (s) => s.id === data?.college_id
                                              )?.name
                                            }
                                          </span>
                                        </p>
                                      </td>
                                      <td className="col-sm-6">
                                        <p style={{ margin: "0 0 0 14px" }}>
                                          <strong>Department : </strong>
                                          <span className="ml-3">
                                            {
                                              departmentOpt?.find(
                                                (s) =>
                                                  s.id == data?.department_id
                                              )?.name
                                            }
                                          </span>
                                        </p>
                                      </td>
                                    </tr>

                                    <br />
                                    <tr className="row ml-5">
                                      <td className="col-sm-6">
                                        <p style={{ margin: "0 0 0 14px" }}>
                                          <strong>Blood Group : </strong>
                                          <span className="ml-3">
                                            {data?.blood_grp}
                                          </span>
                                        </p>
                                      </td>
                                      <td className="col-sm-6">
                                        <p style={{ margin: "0 0 0 14px" }}>
                                          <strong>Marital Status : </strong>
                                          <span className="ml-3">
                                            {data?.marital_status}
                                          </span>
                                        </p>
                                      </td>
                                    </tr>

                                    <br />
                                    <tr className="row ml-5">
                                      <td className="col-sm-6">
                                        <p style={{ margin: "0 0 0 14px" }}>
                                          <strong>Qualification : </strong>
                                          <span className="ml-3">
                                            {data?.qualification}
                                          </span>
                                        </p>
                                      </td>
                                      <td className="col-sm-6">
                                        <p style={{ margin: "0 0 0 14px" }}>
                                          <strong>Adhaar No : </strong>
                                          <span className="ml-3">
                                            {data?.aadhaar}
                                          </span>
                                        </p>
                                      </td>
                                    </tr>

                                    <br />
                                    <tr className="row ml-5">
                                      <td className="col-sm-6">
                                        <p style={{ margin: "0 0 0 14px" }}>
                                          <strong>Nationality : </strong>
                                          <span className="ml-3">
                                            {data?.nationality}
                                          </span>
                                        </p>
                                      </td>
                                      <td className="col-sm-6">
                                        <p style={{ margin: "0 0 0 14px" }}>
                                          <strong>State : </strong>
                                          <span className="ml-3">
                                            {data?.state}
                                          </span>
                                        </p>
                                      </td>
                                    </tr>

                                    <br />
                                    <tr className="row ml-5">
                                      <td className="col-sm-6">
                                        <p style={{ margin: "0 0 0 14px" }}>
                                          <strong>District : </strong>{" "}
                                          <span className="ml-3">
                                            {data?.district}
                                          </span>
                                        </p>
                                      </td>
                                      <td className="col-sm-6">
                                        <p style={{ margin: "0 0 0 14px" }}>
                                          <strong>City : </strong>
                                          <span className="ml-3">
                                            {data?.city}
                                          </span>
                                        </p>
                                      </td>
                                    </tr>

                                    <br />
                                    <tr className="row ml-5">
                                      <td className="col-sm-6">
                                        <p style={{ margin: "0 0 0 14px" }}>
                                          <strong>Religion : </strong>
                                          <span className="ml-3">
                                            {data?.religion}
                                          </span>
                                        </p>
                                      </td>
                                      <td className="col-sm-6">
                                        <p style={{ margin: "0 0 0 14px" }}>
                                          <strong>Caste : </strong>
                                          <span className="ml-3">
                                            {data?.caste}
                                          </span>
                                        </p>
                                      </td>
                                    </tr>

                                    <br />
                                    <tr className="row ml-5">
                                      <td className="col-sm-6">
                                        <p style={{ margin: "0 0 0 14px" }}>
                                          <strong>Category : </strong>
                                          <span className="ml-3">
                                            {data?.category}
                                          </span>
                                        </p>
                                      </td>
                                      <td className="col-sm-6">
                                        <p style={{ margin: "0 0 0 14px" }}>
                                          <strong>Employee Type: </strong>
                                          <span className="ml-3">
                                            {data?.employee_type}
                                          </span>
                                        </p>
                                      </td>
                                    </tr>
                                  </td>
                                  <td className="col-sm-4">
                                    <div className="image-container">
                                      <img
                                        src={
                                          data?.photo
                                            ? data?.photo
                                            : "../../../assets/images/reports/profile.png"
                                        }
                                        className="stafff-img"
                                      />
                                    </div>
                                    <br />
                                    <h3 className="text-center mr-4">
                                      {data?.first_name + " " + data?.last_name}
                                    </h3>
                                    <br />
                                    <div className="mb-3">
                                      <h5 style={{ marginLeft: "30px" }}>
                                        Profile Completion Progress
                                      </h5>
                                      <p
                                        style={{
                                          width: "260px",
                                          marginLeft: "30px",
                                        }}
                                      >
                                        {score !== null ? (
                                          <Progress percent={score} />
                                        ) : (
                                          <p>Loading...</p>
                                        )}
                                      </p>
                                    </div>
                                  </td>
                                  <td className="col-sm-12">
                                    <br />
                                    <br />
                                    <h4 className="text-center my-3 print-staff-details">
                                      CONTACT INFORMATION
                                    </h4>

                                    <table className="table table-bordered staff-details">
                                      <thead>
                                        <tr>
                                          <th>Email</th>
                                          <th>Contact No</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>{data?.email}</td>
                                          <td>{data?.phone}</td>
                                        </tr>
                                      </tbody>
                                    </table>

                                    <br />
                                    <br />
                                    <h4 className="text-center my-3 print-staff-details">
                                      ADDRESS INFORMATION
                                    </h4>
                                    <table className="table table-bordered staff-details">
                                      <thead>
                                        <tr>
                                          <th>Current Address</th>
                                          <th>Permanent Address</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>{data?.current_address}</td>
                                          <td>{data?.permanent_address}</td>
                                        </tr>
                                      </tbody>
                                    </table>

                                    <br />
                                    <br />
                                    <h4 className="text-center my-4 print-staff-details">
                                      EDUCATION INFORMATION
                                    </h4>
                                    <table className="table table-bordered staff-details">
                                      <thead>
                                        <tr>
                                          <th>SL. NO</th>
                                          <th>University</th>
                                          <th>Year</th>
                                          <th>Education Type</th>
                                          <th>Grade</th>
                                        </tr>
                                      </thead>
                                      {data?.other_details?.education_array &&
                                        data?.other_details?.education_array?.map(
                                          (education, index) => (
                                            <tbody>
                                              <tr>
                                                <td>{index + 1}</td>
                                                <td>
                                                  {education?.university_name}
                                                </td>
                                                <td>
                                                  {education?.from_year
                                                    ? education?.from_year + "-"
                                                    : ""}
                                                  {education?.to_year}
                                                </td>
                                                <td>
                                                  {education?.education_type ===
                                                  "other"
                                                    ? education?.other_education_type
                                                    : education?.education_type}
                                                </td>
                                                <td>
                                                  {education?.performance_score
                                                    ? education?.performance_score
                                                    : "__"}
                                                </td>
                                              </tr>
                                            </tbody>
                                          )
                                        )}
                                    </table>

                                    <br />
                                    <br />
                                    <h4 className="text-center my-4 print-staff-details">
                                      EXPERIENCE INFORMATION
                                    </h4>
                                    <table className="table table-bordered staff-details">
                                      <thead>
                                        <tr>
                                          <th>SL. NO</th>
                                          <th>Employer</th>
                                          <th>Job Title</th>
                                          <th>Year</th>
                                          <th>Salary</th>
                                          <th>Country / Region</th>
                                          <th>Remark</th>
                                        </tr>
                                      </thead>
                                      {data?.other_details?.experience_array &&
                                        data?.other_details?.experience_array?.map(
                                          (experience, index) => (
                                            <tbody>
                                              <tr>
                                                <td>{index + 1}</td>
                                                <td>{experience?.employer}</td>
                                                <td>{experience?.job_title}</td>
                                                <td>
                                                  {experience?.from &&
                                                    experience?.from.substring(
                                                      0,
                                                      10
                                                    )}{" "}
                                                  {"->"}{" "}
                                                  {experience?.to &&
                                                    experience?.to.substring(
                                                      0,
                                                      10
                                                    )}{" "}
                                                  ({experience.duration})
                                                </td>
                                                <td>
                                                  {experience?.salary
                                                    ? experience?.salary
                                                    : "__"}
                                                </td>
                                                <td>
                                                  {experience?.region
                                                    ? experience?.region
                                                    : "__"}
                                                </td>
                                                <td>
                                                  {experience?.remark
                                                    ? experience?.remark
                                                    : "__"}
                                                </td>
                                              </tr>
                                            </tbody>
                                          )
                                        )}
                                    </table>

                                    <br />
                                    <br />
                                    <h4 className="text-center my-4 print-staff-details">
                                      TRAINING INFORMATION
                                    </h4>
                                    <table className="table table-bordered staff-details">
                                      <thead>
                                        <tr>
                                          <th>SL. NO</th>
                                          <th>Course</th>
                                          <th>Date</th>
                                          <th>Result</th>
                                          <th>Trainer</th>
                                          <th>Remark</th>
                                        </tr>
                                      </thead>
                                      {data?.other_details?.training_array &&
                                        data?.other_details?.training_array?.map(
                                          (training, index) => (
                                            <tbody>
                                              <tr>
                                                <td>{index + 1}</td>
                                                <td>{training?.course}</td>
                                                <td>
                                                  {training?.date &&
                                                    training?.date.substring(
                                                      0,
                                                      10
                                                    )}
                                                </td>
                                                <td>
                                                  {training?.result
                                                    ? training?.result
                                                    : "__"}
                                                </td>
                                                <td>
                                                  {training?.trainer_id
                                                    ? training?.trainer_id
                                                    : "__"}
                                                </td>
                                                <td>
                                                  {training?.remark
                                                    ? training?.remark
                                                    : "__"}
                                                </td>
                                              </tr>
                                            </tbody>
                                          )
                                        )}
                                    </table>

                                    <br />
                                    <br />
                                    <h4 className="text-center my-4 print-staff-details">
                                      DOCUMENT INFORMATION
                                    </h4>
                                    <table className="table table-bordered staff-details">
                                      <thead>
                                        <tr>
                                          <th>SL. NO</th>
                                          <th>Document</th>
                                          <th>Document Type</th>
                                          <th>Valid From</th>
                                          <th>Valid To</th>
                                        </tr>
                                      </thead>
                                      {docsdata &&
                                        docsdata
                                          ?.filter(
                                            (document) =>
                                              document.employee_id === data.id
                                          )
                                          ?.map((document, index) => (
                                            <tbody>
                                              <tr>
                                                <td>{index + 1}</td>
                                                <td>
                                                  {document?.document_title}
                                                </td>
                                                <td>
                                                  {document?.document_type}
                                                </td>
                                                <td>
                                                  {document?.valid_from &&
                                                  document?.valid_from.substring(
                                                    0,
                                                    10
                                                  )
                                                    ? "Validation"
                                                    : "Validation Upto"}
                                                </td>
                                                <td>
                                                  {document?.valid_from
                                                    ? document?.valid_from &&
                                                      document?.valid_from.substring(
                                                        0,
                                                        10
                                                      ) +
                                                        " -> " +
                                                        (document?.valid_to
                                                          ? document?.valid_to &&
                                                            document?.valid_to.substring(
                                                              0,
                                                              10
                                                            )
                                                          : "Not Available")
                                                    : "Not Available"}
                                                </td>
                                              </tr>
                                            </tbody>
                                          ))}
                                    </table>

                                    <br />
                                    <br />
                                    <h4 className="text-center my-4 print-staff-details">
                                      PLACEMENT INFORMATION
                                    </h4>
                                    <table className="table table-bordered staff-details">
                                      <thead>
                                        <tr>
                                          <th>SL. NO</th>
                                          <th>Course</th>
                                          <th>Date</th>
                                          <th>Result</th>
                                          <th>Trainer</th>
                                        </tr>
                                      </thead>
                                      {trainingdata &&
                                        trainingdata
                                          ?.filter(
                                            (training) =>
                                              training.employee_id === data.id
                                          )
                                          ?.map((training, index) => (
                                            <tbody>
                                              <tr>
                                                <td>{index + 1}</td>
                                                <td>{training?.course}</td>
                                                <td>
                                                  {training?.date &&
                                                    training.date.substring(
                                                      0,
                                                      10
                                                    )}
                                                </td>
                                                <td>
                                                  {training?.result
                                                    ? training?.result
                                                    : "__"}
                                                </td>
                                                <td>
                                                  {training?.trainer_id
                                                    ? training?.trainer_id
                                                    : "__"}
                                                </td>
                                              </tr>
                                            </tbody>
                                          ))}
                                    </table>

                                    <br />
                                    <br />
                                    <h4 className="text-center my-4 print-staff-details">
                                      PUBLICATION INFORMATION
                                    </h4>
                                    <table className="table table-bordered staff-details">
                                      <thead>
                                        <tr>
                                          <th>SL. NO</th>
                                          <th>Publication</th>
                                          <th>Date</th>
                                        </tr>
                                      </thead>
                                      {data?.other_details?.publication_array &&
                                        data?.other_details?.publication_array?.map(
                                          (publication, index) => (
                                            <tbody>
                                              <tr>
                                                <td>{index + 1}</td>
                                                <td>{publication?.name}</td>
                                                <td>
                                                  {publication?.date &&
                                                    publication?.date.substring(
                                                      0,
                                                      10
                                                    )}
                                                </td>
                                              </tr>
                                            </tbody>
                                          )
                                        )}
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          ))
                      )}
                    </div>
                  </section>
                </div>
              </div>
              {/* view Modal */}
            </div>
          </div>

          <table
            id=""
            ref={excelRef}
            className="table table-bordered"
            style={{
              borderCollapse: "collapse",
              borderSpacing: 0,
              width: "100%",
              display: "none",
            }}
          >
            <div className="col-12 text-center">
              <div>
                <h4 className="text-center mb-5">
                  Staff Details List -
                  {
                    collegeOpt?.find(
                      (s) => s.id === employeeData[0]?.college_id
                    )?.name
                  }
                </h4>
                <span>
                  {" "}
                  <p className="text-right float-right mr-5">
                    {" "}
                    Printed On - {" " + getCurrentDate()}{" "}
                  </p>
                </span>
              </div>

              <thead>
                <tr>
                  <th>Sl.No.</th>
                  <th>Employee Name</th>
                  <th>Faculty</th>
                  <th>Department</th>
                  <th>Designation</th>
                  <th>Email</th>
                  <th>Employee Type</th>
                  <th>Mobile No</th>
                  <th>DOB</th>
                  <th>Gender</th>
                  <th>Marital Status</th>
                  <th>Current Address</th>
                  <th>Permanent Address</th>
                  <th>Adhaar No</th>
                  <th>Qualification</th>
                  <th>Work Experience</th>
                  <th>Date Of Joining</th>
                  <th>EPF Number</th>
                  <th>Work Shift</th>
                  <th>Nationality</th>
                  <th>Country</th>
                  <th>State</th>
                  <th>District</th>
                  <th>City</th>
                  <th>Passport No</th>
                  <th>Visa No</th>
                  <th>Visa Issue</th>
                  <th>Visa Expiry</th>
                  <th>Contract Type</th>
                  <th>Account Title</th>
                  <th>Account No</th>
                  <th>Bank Name</th>
                  <th>IFSC Code</th>
                  <th>Bank Branch</th>
                  <th>Basic Salary</th>
                  <th>Deduction</th>
                  <th>Profile Completion</th>
                </tr>
              </thead>
              <tbody>
                {employeeData && employeeData?.length == 0 ? (
                  <tr>
                    <td colSpan={10}>
                      <Nodata />
                    </td>
                  </tr>
                ) : (
                  employeeData
                    ?.filter((post) => {
                      if (search === "") {
                        return post;
                      } else if (
                        (
                          post.first_name.toLowerCase() +
                          " " +
                          post.last_name.toLowerCase()
                        ).includes(search.toLowerCase())
                      ) {
                        return post;
                      }
                    })
                    .filter((s) => s.id != 1)
                    .map((i, key) => {
                      return (
                        <tr key={key}>
                          <td>{key + 1}</td>
                          <td>
                            {i?.first_name} {i?.last_name}
                          </td>
                          <td>
                            {/* {i?.college_id} */}
                            {
                              collegeOpt?.find((s) => s.id === i?.college_id)
                                ?.name
                            }
                          </td>
                          <td>
                            {
                              departmentOpt?.find(
                                (s) => s.id == i?.department_id
                              )?.name
                            }
                          </td>
                          <td>{i?.role}</td>
                          <td>{i?.email}</td>
                          <td>{i?.employee_type}</td>
                          <td>{i?.phone}</td>
                          {/* <td>{i?.dob.split("T")[0]}</td> */}
                          <td>
                            {new Date(i?.dob).toLocaleDateString("en-US")}
                          </td>
                          {/* <td>{i?.dob}</td> */}
                          <td>{i?.gender}</td>
                          <td>{i?.marital_status}</td>
                          <td>{i?.current_address}</td>
                          <td>{i?.permanent_address}</td>
                          <td>{i?.aadhaar}</td>
                          <td>{i?.qualification}</td>
                          <td>{i?.work_experience}</td>

                          <td>
                            {new Date(i?.date_of_joining).toLocaleDateString(
                              "en-US"
                            )}
                          </td>

                          <td>{i?.epf_number}</td>
                          <td>{i?.work_shift}</td>
                          <td>{i?.nationality}</td>
                          <td>{i?.country}</td>
                          <td>{i?.state}</td>
                          <td>{i?.district}</td>
                          <td>{i?.city}</td>
                          <td>{i?.passport_no}</td>
                          <td>{i?.visa_no}</td>
                          <td>{i?.visa_issue}</td>
                          <td>{i?.visa_expiry}</td>
                          <td>{i?.contract_type}</td>
                          <td>{i?.account_title}</td>
                          <td>{i?.account_number}</td>
                          <td>{i?.bank_name}</td>
                          <td>{i?.ifsc_code}</td>
                          <td>{i?.bank_branch}</td>
                          <td>{i?.basic_salary}</td>
                          <td>{i?.deduction}</td>
                          <th>
                            {progressData?.find((s) => s.id == i.id)?.score}%
                          </th>
                        </tr>
                      );
                    })
                )}
              </tbody>
            </div>
          </table>
        </div>
        {/* End Page-content */}
      </>
    </div>
  );
}

export default ViewStaff;
